import React,{useState, useEffect} from 'react'
import { Form, Formik } from 'formik'
import { loginValidator } from '../../validationSchema/validator'
import { Link, useNavigate } from 'react-router-dom'
import eye_off from '../../assets/icons/Hide.svg'
import eye from '../../assets/icons/eye.svg'
import logo from '../../assets/icons/logo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../redux/Auth/authActions'
import OnboardingSidebar from '../../components/OnboardingSidebar/sidebar'


const LoginPage = () => {

    const dispatch = useDispatch()
    const [passwordShown, setPasswordShown] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const {isAuthenticated, loading, email_verified} = useSelector((state) => state.auth);


    const navigate = useNavigate()


    const handleSubmit = (values) => {
        dispatch(userLogin(values))
    }

    useEffect(()=>{
        if(isAuthenticated){
            if(email_verified){
                navigate('/dashboard')
            }else{
                navigate('/email-verification')
            }
        }
      },[isAuthenticated, navigate, email_verified])
    


    return ( 
        <>
            <div className='grid md:grid-cols-2 font-Satoshi400'>
               <OnboardingSidebar />

                <div className='px-6 md:px-20 2xl:px-40 flex flex-col h-full min-h-screen justify-center'>
                    <div className='flex justify-center md:hidden'>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className='text-center mt-5'>
                        <h6 className='text-[#212121] font-Satoshi700 text-xl md:text-2xl'>Welcome back!</h6>
                            <p className='text-[#909090] text-xs'>Enter your details to login</p>
                    </div>

                    <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                        }
                        validationSchema={loginValidator}
                        initialValues={{
                        email: '',
                        password: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10 md:mt-16" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='email' className="block mb-2 text-[#909090] text-xs">
                                  Email Address
                            </label>
                            <input
                                type="text"
                                name="email"
                                placeholder="Enter your email address"
                                className={
                                touched.email && errors.email
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && errors.email ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.email && errors.email}
                                </small>
                            ) : null}
                            </div>

                            <div className="mt-6">
                            <label htmlFor='password' className="block mb-2 text-[#909090] text-xs">
                                Password
                            </label>
                            <div className="relative">
                                <input
                                type={passwordShown ? 'text' : 'password'}
                                name="password"
                                placeholder="Enter your password"
                                className={
                                    touched.password && errors.password
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                    }
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                <img
                                onClick={togglePasswordVisiblity}
                                src={passwordShown ? eye_off : eye}
                                alt="eye"
                                className="absolute w-[18px] h-[18px] top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                            </div>
                            {touched.password && errors.password ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.password && errors.password}
                                </small>
                            ) : null}
                            </div>
                                <div className="mt-1 text-right">
                                    <Link to="/forgot-password"
                                    className="text-[#235643] text-xs  cursor-pointer"
                                    >
                                    Forgot password?
                                    </Link>
                                </div>

                            <div className="mt-6">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Login
                            </button>
                            </div>

                           
                            <div className='text-center mt-10'>
                                <p className='text-[#235643] text-sm'>Don’t have an account? <Link to="/register" className='text-[#235643] font-Satoshi500 text-sm'>Sign Up</Link> </p>
                            </div>

                        
                        </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </> 
     );
}
 
export default LoginPage;