import React,{useState, useEffect} from 'react'
import notransaction from '../../assets/icons/no-transaction.svg'
import arrow_down_green from '../../assets/icons/arrow-down-green.svg'
import arrow_down_red from '../../assets/icons/arrow-down-red.svg'
import { getATransaction, getBillTransactions, getCardTransactions, getWalletTransactions } from '../../redux/Transactions/transactionsActions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Modal from '../../components/Modals/modal'
// import card_sample from '../../assets/images/cardsample.svg'
import convert_card from '../../assets/icons/convert-card.svg'
import wallet_icon from '../../assets/icons/money-recive.svg'
import tag_icon from '../../assets/icons/tag.svg'
import info_icon from '../../assets/icons/info-circle.svg'
import PaginationComponent from '../../components/Pagination/Pagination';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const TransactionsPage = () => {
    const dispatch = useDispatch()

    const {transactions, loading, total, transaction} = useSelector((state) => state.transaction);
    const {currency} = useSelector((state) => state.auth);

    const badgeFormat = (val) => {
        let result
        switch (val) {
          case 'pending':
            result = 'pending'
            break;
          case 'succeed':
            result = 'success'
            break;
        case 'failed':
            result = 'failed'
            break;
        case 'rejected':
            result = 'failed'
            break;
          default:
            result = 'pending'
            break
        }
        return result
      }

      const capitalizeFirstLetter = (str) => {
        let val = str.toLowerCase()
        let words = val.split(" ");
       for (let i = 0; i < words.length; i++) {
           words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
       }
        return words.join(" ");
    }

    const capitalizeFirstLetterFormat = (str) => {
        let res;
        if(str === ""){
            res = 'pending'
        }
        else if(str  === "succeed"){
            res = 'success';
        }
        else{
            res = str
        }

        let val = res.toLowerCase()
        let words = val.split(" ");
       for (let i = 0; i < words.length; i++) {
           words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
       }
        return words.join(" ");
    }

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }
    

    const [tagId, setTagId] =  useState(1)
    const types = [
        {
            id: 1,
            name: 'Giftcards'
        },
        {
            id: 2,
            name: 'Wallets'
        },
        {
            id: 3,
            name: 'Utilities'
        }
    ]

    const toggleTag =  (val) =>{
       setTagId(val)
       switch(val){
        case 1: 
            dispatch(getCardTransactions({
                page: 1
            }))  
         break;
        case 2: 
            dispatch(getWalletTransactions({
                page: 1
            }))
         break;
        case 3:
            dispatch(getBillTransactions({
                page: 1
            }))
            break;
        default:
            break;
       }
    }

    let PageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);


    const getMoreItemsByOffset = (page) => {
        setCurrentPage(page)
        switch(tagId){
            case 1: 
                dispatch(getCardTransactions({
                    page: page
                }))  
             break;
            case 2: 
                dispatch(getWalletTransactions({
                    page: page
                }))
             break;
            case 3:
                dispatch(getBillTransactions({
                    page: page
                }))
                break;
            default:
                break;
           }
    }

    const [showTransactionModal, setShowTransactionModal] = useState(false)
    const TransactionModal = () =>{
        setShowTransactionModal(!showTransactionModal)
    }


    const viewTransaction = (id, reference) =>{
        setShowTransactionModal(true)
        dispatch(getATransaction({
            reference
        }))
    }

    const formatArrow = (val) =>{
        let res;
        switch(val){
            case 'sell':
                res = arrow_down_green
                break;
            case 'buy':
                res = arrow_down_red
                break;
            case 'payout':
                res = arrow_down_green
                break;
            case 'bill':
                res = arrow_down_red
                break;
            default:
                break;
        }
        return res;
    }


    const formatWalletArrow = (val, type) =>{
        let res;
        if(val === "payout"){
            if (type){
                res = arrow_down_red
            }
            else{
                res = arrow_down_green
            }
        }
        else if(val === "wallet"){
            if (type){
                res = arrow_down_red
            }
            else{
                res = arrow_down_green
            }
        }
        else{
            res = arrow_down_green
        }
        return res;
    }

    useEffect(()=>{
        dispatch(getCardTransactions({
            page: 1
        }))
    },[dispatch])
 

    return ( 
        <>

           {/* Transaction Details Modal */}
           <Modal show={showTransactionModal} showModal={TransactionModal} 
         title="Transaction Details"
         >
            <div className='mt-10 grid md:grid-cols-2 gap-4'>
               <div>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Reference</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.reference ? transaction.reference: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Transaction Date</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.created_at ? moment(transaction.created_at).format('MMMM Do YYYY, h:mm:ss a'): ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Status</p>
                            <div className='border border-[#39A307] text-[#39A307] w-fit text-sm font-Satoshi400 rounded-[4px] flex justify-center bg-transparent px-4 py-1 mt-2'>
                            {transaction && transaction.status ? capitalizeFirstLetterFormat(transaction.status): ''}
                            </div>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Last Updated</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>
                            {transaction && transaction.created_at ? moment(transaction.updated_at).format('MMMM Do YYYY, h:mm:ss a'): ''}
                            </h6>
                        </div>
                    </div>
                    
                    <h5 className='text-[#235643] font-Satoshi400 text-sm mt-6'>TRANSACTION FEEDBACK</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Admin’s Feedback</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>
                            {transaction && transaction.admin_comment ? 
                            transaction.admin_comment == null ? 'NIL' : transaction.admin_comment : ''}
                            </h6>
                        </div>
                        
                    </div>
                                
                    {
                     transaction.type !== 'bill' && 
                     <>
                    <h5 className='text-[#235643] font-Satoshi400 text-sm mt-6'>UPLOADED IMAGES</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1'>
                        <div className='flex gap-2 items-center'>
                        {
                            transaction && transaction.images ?
                            transaction.images.map((url, index)=>(
                             <Zoom>
                              <img src={url} alt="card_sample" className='w-[32px] h-[32px] rounded-md' />
                            </Zoom>
                            ))
                            :
                            <div className='text-center '>
                                <p className='text-[#212121] text-xs font-Satoshi500'>No cards uploaded!</p>
                            </div>
                           }
                        </div>
                        
                    </div>
                    </>
                    }
               </div>

               <div>
                     <h5 className='text-[#235643] font-Satoshi400 text-sm'>TRANSACTION INFO</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1 mb-4'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Transaction type</p>
                            <div className='border border-[#39A307] text-[#39A307] uppercase w-fit text-sm font-Satoshi400 rounded-[4px] flex justify-center bg-transparent px-6 py-1 mt-2'>
                            {transaction && transaction.type ? transaction.type: ''}
                            </div>
                        </div>
                    
                        {
                         transaction && transaction.type !== 'bill' &&
                        <>
                      { transaction && transaction.type !== 'buy' &&  <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Gift Card</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.card ? transaction.card.category_name: ''}</h6>
                        </div>
                        }

                       { transaction && transaction.type !== 'buy' &&  <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Rate</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'> {transaction && transaction.card ? transaction.card.rate: ''}/$</h6>
                        </div> }
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Giftcard Value</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>${transaction ? transaction.unit: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Description</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction ? transaction.description: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Amount</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{currency === "NGN" ? '₦' : '₵'}{transaction && transaction.amount ? numberWithCommas(transaction.amount) : ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>User’s Comment</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.comment ? transaction.comment: ''}</h6>
                        </div>
                        </>
                    }

                      {
                         transaction.type === 'bill' &&
                         <>
                      <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Category</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>Bills & Utilities</h6>
                        </div>

                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Type</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.bill ? capitalizeFirstLetter(transaction.bill.product) : ''}</h6>
                        </div>

                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Amount</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{currency === "NGN" ? '₦' : '₵'}{transaction && transaction.amount ? numberWithCommas(transaction.amount) : ''}</h6>
                        </div>

                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Mobile Number</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.bill ? transaction.bill.phone_no: ''}</h6>
                        </div>

                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Token</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.bill ? transaction.bill.token: ''}</h6>
                        </div>

                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Note</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.bill ? transaction.bill.note: ''}</h6>
                        </div>
                        </>
                      }
                        
                        
                    </div>

                    { transaction && transaction.type === 'buy' && 
                    <>
                     <h5 className='text-[#235643] font-Satoshi400 text-sm'>GIFT CARD DETAILS</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1 mb-4'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Card PIN/Code</p>
                                <ol className='mt-2 ml-4 list-disc'>
                                    {
                                        transaction && transaction.redeem_code ? transaction.redeem_code.map((val)=>(
                                        <li className='text-[#212121] font-Satoshi500 text-sm mt-1'>{val.cardNumber !== null ? val.cardNumber : val.pinCode}</li>
                                        ))
                                        :
                                        ""
                                    }
                                  
                                </ol>
                        </div>            
                    </div>
                    </>
                    }

                    {
                      transaction.type === 'bill' &&
                        <a target="_blank" rel="noreferrer"
                         href={`mailto:support@cardbarter.ng?subject=I have a complaint about this transaction!
                             &body=Transaction Type: ${capitalizeFirstLetter(transaction.type)} Transaction Date: ${moment(transaction.created_at).format('MMMM Do YYYY, h:mm:ss a')} 
                             Transaction Reference: ${transaction.reference}`} className='mt-6 cursor-pointer'>
                            <div className='flex gap-1 items-center'>
                                <img src={info_icon} alt="info icon" />
                                <p className='text-[#DC0000] text-sm font-Satoshi500'>Report Transaction</p>
                            </div>
                            <p className='text-xs text-[#909090] font-Satoshi400'>Report an issue with this transaction</p>
                        </a>
                    }
               </div>
                
             </div>            
        </Modal>

            <div>
                   <h4 className='text-[#212121] font-Satoshi700 text-sm md:text-lg'>Transactions</h4>

                   <div className='mt-8 max-w-4xl'>
                        <ul className='flex'>
                                    {
                                types.map((val)=>(
                                    <li key={val.id} onClick={() => toggleTag(val.id)} 
                                        className={
                                            tagId === val.id ?
                                            "border-b-[#235643] border-b-4 flex-1 cursor-pointer"
                                            :
                                            "flex-1 border-b-[#D9D9D9] border-b-4 cursor-pointer"
                                        }
                                        >
                                    <p className={
                                        tagId === val.id
                                        ?
                                        "text-[#235643] font-Satoshi700 text-sm capitalize text-center mb-1"
                                        :
                                        "text-[#D9D9D9] font-Satoshi700 text-sm capitalize text-center mb-1"
                                    }
                                    >{val.name}</p>
                                </li>
                                ))  
                                }
                         </ul>

                      {/* transactions table */}
                        <div className='bg-[#FBFBFB] mt-8 rounded-xl py-4 px-4'>
                            <div className="overflow-x-auto relative">
                            <table className="w-full text-left">
                                <thead className="text-[#010918] font-Satoshi700 bg-transparent text-xs">
                                <tr>
                                    <th scope="col" className="py-2 px-6">
                                    Type
                                    </th>
                                    <th scope="col" className="py-2 px-6">
                                      Amount
                                    </th>
                                    <th scope="col" className="py-2 px-6">
                                    Date/Time
                                    </th>
                                    <th scope="col" className="py-2 px-6">
                                    Status
                                    </th>
                                </tr>
                            </thead>

                            {!loading && (
                            <tbody>
                                {transactions.length > 0
                                ? transactions.map((item) => (
                              <tr
                              onClick={()=> viewTransaction(item.id, item.reference)}
                               key={item.id} className="bg-white text-xs font-Satoshi400 rounded-lg hover:bg-gray-100 hover:rounded-lg cursor-pointer">
                                <td
                                className="py-3 px-4 text-[#212121] whitespace-nowrap "
                                >
                                    <div className='flex gap-2 items-center'>
                                       {item.type === 'sell' && <div className = 'flex justify-center items-center w-[36px] h-[36px] bg-white rounded-full'>
                                            <img src={convert_card} alt="card" />
                                        </div>}
                                        {item.type === 'buy' && <div className = 'flex justify-center items-center w-[36px] h-[36px] bg-white rounded-full'>
                                            <img src={convert_card} alt="card" />
                                        </div>}
                                        {item.type === 'payout' && <div className = 'flex justify-center items-center w-[36px] h-[36px] bg-white rounded-full'>
                                            <img src={wallet_icon} alt="card" />
                                        </div>
                                        }
                                        {item.type === 'bill' && <div className = 'flex justify-center items-center w-[36px] h-[36px] bg-white rounded-full'>
                                            <img src={tag_icon} alt="card" />
                                        </div>
                                            }     
                                        <span>{capitalizeFirstLetter(item.type === 'sell' ? item.card.category_name : item.type === 'bill' ? item.bill.product: item.type)}</span>
                                    </div>
                            
                                </td>
                                <td className="py-3 px-4 text-[#212121] font-Satoshi500">
                                    <div className='flex gap-1 items-center'>
                                     {
                                        tagId === 2 ?
                                        <img src={formatWalletArrow(item.type, item.isDebit)} alt="arrow" />
                                        :
                                        <img src={formatArrow(item.type)} alt="arrow" />
                                     }  
                                        
                                        <span>{currency === "NGN" ? '₦' : '₵'}{numberWithCommas(item.amount)}</span>
                                    </div>
                                
                                </td>
                                <td className="py-3 px-4 text-[#909090]">
                                    {moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                                </td>
                                <td className="py-3 px-4  text-[#212121]">
                                    <div className='flex items-center max-w-[120px] justify-center bg-[#F7F7F7] rounded-[36px] px-6 py-2'>
                                        <p className={badgeFormat(item.status)}>{capitalizeFirstLetterFormat(item.status)}</p>
                                    </div>
                                </td>     
                                </tr>
                                    ))
                                : ''}
                            </tbody>
                            )} 
                           
                                
                            </table>
                             </div>

                             {loading && (
                                <div className="animate-pulse w-full mt-4">
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                                </div>
                            )}

                            
                             {/* no transactions */}
                             {!loading && transactions.length === 0 ? (
                                  <div className='flex flex-col min-h-[400px] justify-center items-center'>
                                  <div>
                                      <img src={notransaction} alt="no transaction icon" />
                                  </div>
                                  <div className='mt-6'>
                                      <h6 className='text-[#212121] font-Satoshi500 text-lg'>No Recent Transaction</h6>
                                  </div>
                                  <div className='mt-1'>
                                      <p className='text-[#909090] text-sm font-Satoshi400'>Trade now to get started</p>
                                  </div>
                                  <div className='mt-8'>
                                    <Link to="/trade-now" className='bg-[#235643] rounded-md
                                        disabled:bg-[#235643] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Satoshi500
                                        justify-center
                                        flex items-center min-w-[248px]
                                        py-4 px-6 text-[#FFFFFF]
                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                        Trade Now
                                    </Link>
                                  </div>
  
                              </div>
                            ) : (
                                ''
                          )}
                        </div>

                          {/* pagination */}
                          {
                            loading ?
                                ""
                                :
                            transactions.length > 0 && transactions ?
                            <div className="flex justify-center mt-5 mb-10">
                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalCount={total}
                                    pageSize={PageSize}
                                    onPageChange={(page) => getMoreItemsByOffset(page)}
                                />
                            </div>
                            :
                            ""
                        }

                    </div>

            </div>
        </>
     );
}
 
export default TransactionsPage;