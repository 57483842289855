import React,{useEffect, useState} from 'react'
import naira from '../../assets/icons/naira.svg'
import cedis from '../../assets/icons/cedis.svg'
import add_circle from '../../assets/icons/add-circle-white.svg'
import add_green from '../../assets/icons/add-green.svg'
import flag_nigeria from '../../assets/icons/flag-for-nigeria.svg'
import flag_ghana from '../../assets/icons/flag-for-ghana.svg'
import chevron_down from '../../assets/icons/chevron_down.svg'
import chevron_down_black from '../../assets/icons/chevron_down_black.svg'
import minus_circle from '../../assets/icons/minus-circle.svg'
import card_send from '../../assets/icons/card-send.svg'
import search_icon from '../../assets/icons/search-normal.svg'
import arrow_right from '../../assets/icons/arrow-right.svg'
import arrow_down_green from '../../assets/icons/arrow-down-green.svg'
import arrow_down_red from '../../assets/icons/arrow-down-red.svg'
import global_icon from '../../assets/icons/global.svg'
import electricity_icon from '../../assets/icons/electricity.svg'
import airtime_icon from '../../assets/icons/call-calling.svg'
import wifi_icon from '../../assets/icons/wifi.svg'
import game_icon from '../../assets/icons/game.svg'
// import menu_icon from '../../assets/icons/menu.svg'
import reverse_icon from '../../assets/icons/reverse.svg'
import notransaction from '../../assets/icons/no-transaction.svg'
// import card_sample from '../../assets/images/cardsample.svg'
import vba_img from '../../assets/icons/Layer_1.svg'
import question_icon from '../../assets/icons/question.svg'
import shield_icon from '../../assets/icons/shield.svg'
import shield_check from '../../assets/icons/shield_check.svg'
import copy_icon from '../../assets/icons/document-copy-black.svg'
import Modal from '../../components/Modals/modal'
import { Form, Formik } from 'formik'
import { buyDataValidator, buyAirtimeValidator,buyCableValidator, buyElectricityValidator, buyWifiValidator, bankAccountValidatorNG, bankAccountValidatorGH, withdrawalAccountValidator, withdrawUSDTAccountValidator, verifyKYCValidator } from '../../validationSchema/validator'
// import cogotoast from 'cogo-toast'
import PinInput from "react-pin-input";
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getRecentTransactions, getATransaction } from '../../redux/Transactions/transactionsActions'
import moment from 'moment'
import { addAccountDetails, confirmTransactionPin, getBanks, getWalletBalance, setTransactionPin, verifyBankAccount, verifyKYC,createVBAAccount,getVBADetails } from '../../redux/Auth/authActions'
import chevron_grey from '../../assets/icons/chevron_grey.svg'
import convert_card from '../../assets/icons/convert-card.svg'
import hand_icon from '../../assets/icons/hand.svg'
import { getCablePlans, getDataBundles, getWifiPlans, purchaseAirtime, purchaseCable, purchaseDataBundles, purchaseElectricity, purchaseWifi } from '../../redux/Dashboard/dashboardActions'
import { clearPurchaseSuccess } from '../../redux/Dashboard/dashboard'
import { clearAccountName, clearKYCVerified, clearPinConfirm, clearvbaCreated, switchCurrency } from '../../redux/Auth/auth'
import { getUSDTNetworks, getUSDTWithdrawalRate, getWithdrawalAccount, withdrawToFiat, withdrawUSDT } from '../../redux/Withdrawals/withdrawalActions'
import PaginationComponent from '../../components/Pagination/Pagination';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import cogoToast from 'cogo-toast'
import { clearWithdrawal } from '../../redux/Withdrawals/withdrawal'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const DashboardPage = () => {


    let PageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);


    const getMoreItemsByOffset = (page) => {
        setCurrentPage(page)
        dispatch(getRecentTransactions({
            page: page
        }))
    }

    const [showDataModal, setShowDataModal] = useState(false)
    const [showAirtimeModal, setShowAirtimeModal] = useState(false)
     const [showCableModal, setShowCableModal] = useState(false)
     const [showElectricityModal, setShowElectricityModal] = useState(false)
     const [showWifiModal, setShowWifiModal] = useState(false)
    const dispatch = useDispatch()

    const DataModal = () =>{
        setShowDataModal(!showDataModal)
        dispatch(clearPurchaseSuccess())
    }

    const AirtimeModal = () =>{
        setShowAirtimeModal(!showAirtimeModal)
    }

    const CableModal = () =>{
        setShowCableModal(!showCableModal)
    }

    const ElectricityModal = () =>{
        setShowElectricityModal(!showElectricityModal)
    }

    const WifiModal = () =>{
        setShowWifiModal(!showWifiModal)
    }

    const {userInfo, walletbalance, setPinLoader, pin_set, banks, accountName,addLoader, isPinConfirmed, confirmWithdrawLoader, currency, kycLoader, banking_verified, vbaLoader, hasVBA, bankDetails, vbaCreated,kycVerified} = useSelector((state) => state.auth);
    const {recentTransactions, loading, total, transaction} = useSelector((state) => state.transaction);
    const {databundles, isPurchaseSuccess, purchaseLoader, cableplans, wifiplans} = useSelector((state) => state.dashboard);
    const {withdrawalAccounts,withdrawLoader, networks, USDTRate, withdrawSuccess} = useSelector((state) => state.withdrawal);

    const capitalizeFirstLetter = (str) => {
        let val = str.toLowerCase()
        let words = val.split(" ");
       for (let i = 0; i < words.length; i++) {
           words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
       }
        return words.join(" ");
    }

    const badgeFormat = (val) => {
        let result
        switch (val) {
          case 'pending':
            result = 'pending'
            break;
          case 'succeed':
            result = 'success'
            break;
        case 'failed':
            result = 'failed'
            break;
        case 'rejected':
            result = 'failed'
            break;
          default:
            result = 'pending'
            break
        }
        return result
      }

    const capitalizeFirstLetterFormat = (str) => {
        let res;
        if(str === ""){
            res = 'pending';
        }
        else if(str  === "succeed"){
            res = 'success';
        }
        else{
            res = str
        }

        let val = res.toLowerCase()
        let words = val.split(" ");
       for (let i = 0; i < words.length; i++) {
           words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
       }
        return words.join(" ");
    }

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }
    

    const [showWithdrawModal, setShowWithdrawModal] = useState(false)
    const WithdrawModal = () =>{
        setShowWithdrawModal(!showWithdrawModal)
    }

    const [showVBAInfoModal, setShowVBAInfoModal] = useState(false)
    const VBAInfoModal = () =>{
        setShowVBAInfoModal(!showVBAInfoModal)
    }

    const [showVerifyKYCModal, setShowVerifyKYCModal] = useState(false)
    const VerifyKYCModal = () =>{
        setShowVerifyKYCModal(!showVerifyKYCModal)
    }

    const toggleVerifyKYCModal = () =>{
        setShowVBAInfoModal(false)

        setTimeout(()=>{
            setShowVerifyKYCModal(true)
        },800)
     }

    const [showBVNInfoModal, setShowBVNInfoModal] = useState(false)
     const BVNInfoModal = () =>{
        setShowBVNInfoModal(!showBVNInfoModal)
     }

     const [showIdentityModal, setShowIdentityModal] = useState(false)
     const IdentityVerifyModal = () =>{
        setShowIdentityModal(!showIdentityModal)
     }


     const [showFundWalletModal, setShowFundWalletModal] = useState(false)
     const FundWalletModal = () =>{
        setShowFundWalletModal(!showFundWalletModal)
     }

    const [tagId, setTagId] =  useState(1)
    const types = [
        {
            id: 1,
            name: 'Withdraw to Bank'
        }
    ]

    const toggleTag =  (val) =>{
        setTagId(val)
     }

     const [showAccountModal, setShowAccountModal] = useState(false)
     const AccountModal = () =>{
        setShowAccountModal(!showAccountModal)
     }

     const toggleAddAccount = () =>{
        dispatch(clearAccountName())
        setShowWithdrawModal(false)

        setTimeout(()=>{
            setShowAccountModal(true)
        },800)
     }

     const [showPINModal, setShowPINModal] = useState(false)
     const PINModal = () =>{
        setShowPINModal(!showPINModal)
     }

     const [showTransactionPINModal, setShowTransactionPINModal] = useState(false)
     const TransactionPINModal = () =>{
        setShowTransactionPINModal(!showTransactionPINModal)
     }

     const [valid] = useState(false)
     const [pinValid, setPinValid] = useState(false)
     const [pin, setPin] = useState("");
     const [newpin, setNewPin] = useState("");
     const [confirmpin, setConfirmPin] = useState("");


     const handlePin = (val) => {
         setPin(val);
     };

     const handleNewPin = (val) => {
        setNewPin(val);
    };

    const handleConfirmPin = (val) => {
        setPinValid(false)
        setConfirmPin(val);
    };

    //  const handleSubmit = (values) => {
    //     console.log(values)
    // }

    const [showTransactionModal, setShowTransactionModal] = useState(false)
    const TransactionModal = () =>{
        setShowTransactionModal(!showTransactionModal)
    }

 

    const viewTransaction = (id,reference) =>{
        setShowTransactionModal(true)
        dispatch(getATransaction({
            reference
        }))
    }

    const handleDataBundles = (val) =>{
        dispatch(getDataBundles({
            network: val
        }))
    }

    const handleCablePlans = (val) =>{
        dispatch(getCablePlans({
            provider: val
        }))
    }

    const handleWifiPlan = (val) =>{
        dispatch(getWifiPlans({
            provider: val
        })) 
    }

    const handleBuyData = (values) =>{
        dispatch(purchaseDataBundles({
            currency: values.wallet,
            phone_no: values.phonenumber,
            code: values.bundle,
            product: values.network,
            amount: parseInt(values.amount)
        }))
    }

    const handleBuyAirtime = (values) =>{
        dispatch(purchaseAirtime({
            currency: values.wallet,
            phone_no: values.phonenumber,
            product: values.network,
            amount: parseInt(values.amount)
        }))
    }

    const handleBuyCable = (values) =>{
        dispatch(purchaseCable({
            currency: values.wallet,
            phone_no: values.phonenumber,
            code: values.package,
            product: values.provider,
            amount: parseInt(values.amount),
            smart_card_no: values.smartcardnumber
        }))
    }

    const handleBuyElectricity = (values) =>{
        dispatch(purchaseElectricity({
            currency: values.wallet,
            phone_no: values.phonenumber,
            product: values.provider,
            amount: parseInt(values.amount),
            meter_no: values.meternumber,
            meter_type: values.metertype
        }))
    }

    const handleBuyWifi = (values) =>{
        dispatch(purchaseWifi({
            currency: values.wallet,
            device_no: values.phonenumber,
            product: values.provider,
            amount: parseInt(values.amount),
            code: values.wifiplan,
        }))
    }

    const setTransactPin = () =>{
        if(newpin !== "" && confirmpin !== ""){
            if(newpin !== confirmpin){
                setPinValid(true)
            } 
            else{
                dispatch(setTransactionPin({
                    pin: newpin
                }))
            }
        }
        else{
            cogoToast.info("Please fill in all required fields")
        }
    }

    const handleVerifyAcct = (banknumber, code) =>{
        let re = /^-?[0-9]+(.[0-9]{1-7})?$/;
        if(re.test(banknumber)){
            if(currency === 'NGN'){
                if(banknumber.length === 10 && code !== ""){
                    dispatch(verifyBankAccount({
                        bankname: code,
                        banknumber: banknumber,  
                    }))
                }  
            }
        }
    }

    const handleBank = (code, banknumber) =>{
        if(currency === 'NGN'){
            dispatch(clearAccountName())
            let re = /^-?[0-9]+(.[0-9]{1-7})?$/;
            if(re.test(banknumber)){
                if(banknumber.length === 10){
                    dispatch(verifyBankAccount({
                        bankname: code,
                        banknumber: banknumber,  
                    }))
                }
            }
        }
    }

    const [GHAccountName, setGHAccountName] = useState("")
    const handleAccountName = (val) =>{
        setGHAccountName(val)
    }

    const addAccountInfo = (values) =>{
        let bankName = banks.find((val) => val.code === values.bank).name
        dispatch(addAccountDetails({
            code: values.bank,
            bankname: bankName,
            banknumber: values.accountNumber,
            accountname:  currency === "NGN" ? accountName : GHAccountName
        }))
        setTimeout(()=>{
            setShowAccountModal(false)
        },1000)
    }

    const fetchWithdrawalAccts = (val) =>{
        dispatch(getWithdrawalAccount({
            currency: val
        }))
    }

    const [cryptoCurrency, setCryptoCurrency] = useState("NGN")
    const [showUSDTMenu, setShowUSDTMenu] = useState(false)
    const toggleUSDTCurrency = () =>{
        setShowUSDTMenu(!showUSDTMenu)
    }

    const handleUSDTNigeria = () =>{
        setShowUSDTMenu(false)
        setCryptoCurrency("NGN")
        // get withdrawal rate for NGN then make your calculation with that rate
        dispatch(getUSDTWithdrawalRate({
            currency: "NGN"
        }))
    }

    const handleUSDTGhana = () =>{
        setShowUSDTMenu(false)
        setCryptoCurrency("GHS")
        // get withdrawal rate for GHS then make calculation with that rate
        dispatch(getUSDTWithdrawalRate({
            currency: "GHS"
        }))
    }

    const [amount, setAmount] = useState(0);
    const [usdtAmount, setUsdtAmount] = useState(0);

    const handleAmount = (val) =>{
       setAmount(parseFloat(val))
       // check what fiat it is and multiply amount by the fiat rate
       if(val === ""){
           setUsdtAmount(0)
       }
       else{
           if(cryptoCurrency === "NGN"){
               let res = parseFloat(val) / (USDTRate ? USDTRate.NGN : 0)
               setUsdtAmount(res)
           }
           if(cryptoCurrency === "GHS"){
               let res = parseFloat(val) / (USDTRate ? USDTRate.GHS : 0)
               setUsdtAmount(res)
           }
       }
    }

    const handleUSDTAmount = (val) =>{
        setUsdtAmount(parseFloat(val))
         // check what fiat it is and multiply amount by the fiat rate
       if(val === ""){
        setAmount(0)
        }
        else{
            if(cryptoCurrency === "NGN"){
                let res = parseFloat(val) * (USDTRate ? USDTRate.NGN : 0)
                setAmount(res)
            }
            if(cryptoCurrency === "GHS"){
                let res = parseFloat(val) * (USDTRate ? USDTRate.GHS : 0)
                setAmount(res)
            }
        }
    }

    const [fiatInfo, setFiatInfo] = useState({})
    const withdrawFunds = (values) =>{
        setShowWithdrawModal(false)
        setFiatInfo(
            {
                ...fiatInfo,
                bank: parseInt(values.withdrawalAcct),
                amount: parseInt(values.amount),
                currency: values.wallet 
            }
        )
        setTimeout(()=>{
            setShowPINModal(true)
        },800)

    }

    const [usdtInfo, setUsdtInfo] = useState({})
    const withdrawFundsAsUSDt = (values) =>{
        if(!Number.isInteger(amount)){
            cogoToast.warn("Pleae fill in a valid amount for your withdrawal!")
        }
        else{
        setShowWithdrawModal(false)
        setUsdtInfo(
            {
                ...usdtInfo,
                amount: parseFloat(amount),
                currency: cryptoCurrency,
                wallet_address: values.wallet_address,
                network: values.network
            }
        )
        setTimeout(()=>{
            setShowPINModal(true)
        },800)
     }
    }

    const confirmWithdrawal = () =>{
        if(pin !== ""){
            dispatch(confirmTransactionPin({
                pin: pin
            }))
        }
        else{
            cogoToast.info("Please fill in all required fields")
        }
    }
    
    useEffect(()=>{
        if(pin_set){
            setShowTransactionPINModal(false)
        }
    },[pin_set])

    useEffect(()=>{
        if(isPinConfirmed){
            if(tagId === 1){
                dispatch(withdrawToFiat({
                    bank: fiatInfo.bank,
                    amount: fiatInfo.amount,
                    currency: fiatInfo.currency
                }))
            }
            else{
                dispatch(withdrawUSDT({
                    amount: usdtInfo.amount,
                    currency: usdtInfo.currency,
                    wallet_address: usdtInfo.wallet_address,
                    network: usdtInfo.network
                }))  
            }
        }
    },[isPinConfirmed, dispatch, fiatInfo, usdtInfo])

    useEffect(()=>{
        if(withdrawSuccess){
            dispatch(clearPinConfirm())
            setFiatInfo({})
            setUsdtInfo({})
            setTimeout(() => {
                setShowPINModal(false)
                dispatch(clearWithdrawal())
                dispatch(getWalletBalance())
            }, 1000);
        }
    },[withdrawSuccess, dispatch])

   
    const [showCurrencyMenu, setShowCurrencyMenu] = useState(false)

    const toggleCurrency = () =>{
        setShowCurrencyMenu(!showCurrencyMenu)
    }

    const handleNigeriaCurrency = () =>{
        setShowCurrencyMenu(false)
        dispatch(switchCurrency("NGN"))
        cogoToast.success("Wallet switched successfully",{
            position: 'top-right'
        })
        setTimeout(()=>{
            dispatch(getRecentTransactions({
                page: 1
            }))
            dispatch(getWalletBalance())
            dispatch(getBanks()) 
        },1000)
    }

    const handleGhanaCurrency = () =>{
        setShowCurrencyMenu(false)
        dispatch(switchCurrency("GHS"))
        cogoToast.success("Wallet switched successfully",{
            position: 'top-right'
        })

        setTimeout(()=>{
            dispatch(getRecentTransactions({
                page: 1
            }))
            dispatch(getWalletBalance())
            dispatch(getBanks()) 
        },1000)
    }

    const handleVerifyKYC = (values) =>{
        let firstName = userInfo ? userInfo.firstname : ""
        let lastName = userInfo ? userInfo.lastname : ""
        // verify if firstname and lastname entered is same with names on their profile, if not prompt them to update profile first
        if(firstName === "" || lastName === ""){
            cogoToast.warn("Kindly update your profile with these names before you can proceed!")
        }
        else if(firstName !== values.firstname){
            cogoToast.warn("Kindly update your profile with these names before you can proceed!")
        }
        else if(lastName !== values.lastname){
            cogoToast.warn("Kindly update your profile with these names before you can proceed!")
        }
        else{
            dispatch(verifyKYC({
                firstname: values.firstname,
                lastname: values.lastname,
                bvn: values.bvn
            }))
        }
       
    }

    useEffect(()=>{
        if(kycVerified){
            setShowVerifyKYCModal(false)
            // show the success modal for verify bvn and to request for vba account
            setTimeout(()=>{
                setShowIdentityModal(true)
                dispatch(clearKYCVerified())
            },500)

        }
    },[kycVerified])

    const handleRequestVBA = () =>{
        dispatch(createVBAAccount())
    }

    useEffect(()=>{
        if(vbaCreated){
            setShowVBAInfoModal(false)
            setShowIdentityModal(false)
            // fetch vba details
            dispatch(getVBADetails())

            // clear vba created
            setTimeout(()=>{
                dispatch(clearvbaCreated())
            },500)
        }
    },[vbaCreated])

    const handleCopy = () =>{
        cogoToast.success('Account number copied!')
    }


    useEffect(()=>{
        dispatch(getVBADetails())
    },[dispatch])
   

    useEffect(()=>{
        if(!pin_set){
            setShowTransactionPINModal(true)
        }
      },[pin_set])

    useEffect(()=>{
        if(isPurchaseSuccess){
            if(showDataModal){
                setShowDataModal(false)
            }
            if(showAirtimeModal){
              setShowAirtimeModal(false)
            }
            if(showCableModal){
                setShowCableModal(false)
            }
            if(showElectricityModal){
                setShowElectricityModal(false)
            }
            if(showWifiModal){
                setShowWifiModal(false)
            }
            setTimeout(() => {
              dispatch(clearPurchaseSuccess())
              dispatch(getRecentTransactions())
              dispatch(getWalletBalance())  
            }, 1000);
        }
    },[isPurchaseSuccess, dispatch, showWifiModal, showAirtimeModal, showCableModal, showElectricityModal, showDataModal])

    useEffect(()=>{
        dispatch(getRecentTransactions({
            page: 1
        }))
        dispatch(getWalletBalance())
        dispatch(getBanks())
        dispatch(getUSDTNetworks())
        dispatch(getUSDTWithdrawalRate({
            currency: cryptoCurrency
        }))
    },[dispatch])
 

    return (  
        <>

         {/* Buy Data Modal */}
        <Modal show={showDataModal} showModal={DataModal} 
         title="Buy Data"
         >
            <div>
              <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleBuyData(values, setSubmitting)
                        }
                        validationSchema={buyDataValidator}
                        initialValues={{
                        wallet: '',
                        network: '',
                        bundle: '',
                        amount: '0',
                        phonenumber: ''
                     }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='wallet' className="block mb-2 text-[#909090] text-xs">
                             Select Wallet
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="wallet"
                                    placeholder="Select Wallet"
                                    className='appearance-none w-full cursor-pointer 
                                    disabled:text-red-100
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px]  bg-[#F7F9FB] py-4 px-4'   
                                        value={values.wallet}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                >
                                    <option value="" selected disabled>Select a wallet</option>
                                    <option value="NGN">Naira (NGN)</option>
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                              <small className='text-[#212121] block font-[300] text-[10px] pt-[4px]'>
                                <i
                                    className="mdi mdi-information mr-1"
                                    style={{ color: "#FF8400" }}
                                ></i>
                                Available for only Naira Wallet
                                </small>
                            {touched.wallet && errors.wallet ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.wallet && errors.wallet}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='network' className="block mb-2 text-[#909090] text-xs">
                            Select Network
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="network"
                                    placeholder="Select network provider"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px] bg-[#F7F9FB] py-4 px-4'
                                    value={values.network}
                                    onChange={(e) => {
                                        handleChange(e)
                                        handleDataBundles(e.currentTarget.value)
                                        setFieldValue('bundle', "")
                                        setFieldValue('amount', "0")
                                        setFieldTouched('network', false)
                                    }}
                                    onBlur={handleBlur}   
                                >
                                    <option value="" selected={values.network === "" } disabled>Select network provider</option>
                                    <option value="mtn" >MTN</option>
                                    <option value="glo">GLO</option>
                                    <option value="airtel">AIRTEL</option>
                                    <option value="9mobile">9mobile</option>
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.network && errors.network ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.network && errors.network}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='bundle' className="block mb-2 text-[#909090] text-xs">
                            Available Bundles
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="bundle"
                                    placeholder="Select a bundle"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px]  bg-[#F7F9FB] py-4 px-4' 
                                        value={values.bundle}
                                        onChange={(e) => {
                                            handleChange(e)
                                            setFieldValue('amount',databundles.find((pro) => pro.code === e.currentTarget.value).amount)
                                            setFieldTouched('bundle', false)
                                        }}
                                        onBlur={handleBlur}  
                                >
                                    <option value="" selected={values.bundle === "" } disabled>Select a bundle</option>
                                    {databundles.map((opt, index) => {
                                        return <option key={index} value={opt.code}>{opt.name}</option>
                                        })} 
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.bundle && errors.bundle ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.bundle && errors.bundle}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='amount' className="block mb-2 text-[#909090] text-xs">
                            Amount
                            </label>
                            <input
                                type="text"
                                name="amount"
                                disabled
                                placeholder="Enter an amount"
                                className={
                                touched.amount && errors.amount
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            </div>

                         <div className='mt-6'>
                            <label htmlFor='phonenumber' className="block mb-2 text-[#909090] text-xs">
                            Device Number
                            </label>
                            <input
                                type="text"
                                name="phonenumber"
                                placeholder="Enter your device number"
                                className={
                                touched.phonenumber && errors.phonenumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.phonenumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.phonenumber && errors.phonenumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.phonenumber && errors.phonenumber}
                                </small>
                            ) : null}
                            </div>

                         <div className="mt-10">
                            <button
                                type="submit"
                                disabled={purchaseLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Buy Data
                            </button>
                            </div>                        
                        </Form>
                        )}
                    </Formik>
             </div>            
        </Modal>

        {/* Buy Airtime Modal */}
        <Modal show={showAirtimeModal} showModal={AirtimeModal} 
         title="Buy Airtime"
         >
            <div>
              <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleBuyAirtime(values, setSubmitting)
                        }
                        validationSchema={buyAirtimeValidator}
                        initialValues={{
                        wallet: '',
                        network: '',
                        amount: '',
                        phonenumber: ''
                     }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='wallet' className="block mb-2 text-[#909090] text-xs">
                             Select Wallet
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="wallet"
                                    placeholder="Select Wallet"
                                    className='appearance-none w-full cursor-pointer 
                                    disabled:text-red-100
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px]  bg-[#F7F9FB] py-4 px-4'   
                                        value={values.wallet}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                >
                                    <option value="" selected disabled>Select a wallet</option>
                                    <option value="NGN">Naira (NGN)</option>
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                              <small className='text-[#212121] block font-[300] text-[10px] pt-[4px]'>
                                <i
                                    className="mdi mdi-information mr-1"
                                    style={{ color: "#FF8400" }}
                                ></i>
                                Available for only Naira Wallet
                                </small>
                            {touched.wallet && errors.wallet ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.wallet && errors.wallet}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='network' className="block mb-2 text-[#909090] text-xs">
                            Select Network
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="network"
                                    placeholder="Select network provider"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px] bg-[#F7F9FB] py-4 px-4'
                                    value={values.network}
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                    onBlur={handleBlur}   
                                >
                                    <option value="" selected={values.network === "" } disabled>Select network provider</option>
                                    <option value="mtn" >MTN</option>
                                    <option value="glo">GLO</option>
                                    <option value="airtel">AIRTEL</option>
                                    <option value="9mobile">9mobile</option>
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.network && errors.network ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.network && errors.network}
                                </small>
                            ) : null}
                            </div>
    
                        <div className='mt-6'>
                            <label htmlFor='amount' className="block mb-2 text-[#909090] text-xs">
                            Amount
                            </label>
                            <input
                                type="text"
                                name="amount"
                                placeholder="Enter an amount"
                                className={
                                touched.amount && errors.amount
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            </div>

                         <div className='mt-6'>
                            <label htmlFor='phonenumber' className="block mb-2 text-[#909090] text-xs">
                            Mobile Number
                            </label>
                            <input
                                type="text"
                                name="phonenumber"
                                placeholder="Enter your mobile number"
                                className={
                                touched.phonenumber && errors.phonenumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.phonenumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.phonenumber && errors.phonenumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.phonenumber && errors.phonenumber}
                                </small>
                            ) : null}
                            </div>

                         <div className="mt-10">
                            <button
                                type="submit"
                                disabled={purchaseLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Buy Airtime
                            </button>
                            </div>                        
                        </Form>
                        )}
                    </Formik>
             </div>            
        </Modal>

        {/* Buy cable Modal */}
        <Modal show={showCableModal} showModal={CableModal} 
         title="Buy Cable"
         >
            <div>
              <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleBuyCable(values, setSubmitting)
                        }
                        validationSchema={buyCableValidator}
                        initialValues={{
                        wallet: '',
                        provider: '',
                        package: '',
                        amount: '0',
                        smartcardnumber: '',
                        phonenumber: ''
                     }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='wallet' className="block mb-2 text-[#909090] text-xs">
                             Select Wallet
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="wallet"
                                    placeholder="Select Wallet"
                                    className='appearance-none w-full cursor-pointer 
                                    disabled:text-red-100
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px]  bg-[#F7F9FB] py-4 px-4'   
                                        value={values.wallet}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                >
                                    <option value="" selected disabled>Select a wallet</option>
                                    <option value="NGN">Naira (NGN)</option>
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                              <small className='text-[#212121] block font-[300] text-[10px] pt-[4px]'>
                                <i
                                    className="mdi mdi-information mr-1"
                                    style={{ color: "#FF8400" }}
                                ></i>
                                Available for only Naira Wallet
                                </small>
                            {touched.wallet && errors.wallet ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.wallet && errors.wallet}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='provider' className="block mb-2 text-[#909090] text-xs">
                            Select Cable Provider
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="provider"
                                    placeholder="Select network provider"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px] bg-[#F7F9FB] py-4 px-4'
                                    value={values.provider}
                                    onChange={(e) => {
                                        handleChange(e)
                                        handleCablePlans(e.currentTarget.value)
                                        setFieldValue('package', "")
                                        setFieldValue('amount', "0")
                                        setFieldTouched('provider', false)
                                    }}
                                    onBlur={handleBlur}   
                                >
                                    <option value="" selected={values.provider === "" } disabled>Select cable provider</option>
                                    <option value="DStv" >DSTV</option>
                                    <option value="GOtv">GOTV</option>
                                    <option value="StarTimes">STARTIMES</option>
                                    
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.provider && errors.provider ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.provider && errors.provider}
                                </small>
                            ) : null}
                            </div>


                            <div className='mt-6'>
                            <label htmlFor='package' className="block mb-2 text-[#909090] text-xs">
                            Select Package
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="package"
                                    placeholder="Select a package"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px]  bg-[#F7F9FB] py-4 px-4' 
                                        value={values.package}
                                        onChange={(e) => {
                                            handleChange(e)
                                            setFieldValue('amount',cableplans.find((pro) => pro.code === e.currentTarget.value).amount)
                                            setFieldTouched('package', false)
                                        }}
                                        onBlur={handleBlur}  
                                >
                                    <option value="" selected={values.bundle === "" } disabled>Select a package</option>
                                    {cableplans.map((opt, index) => {
                                        return <option key={index} value={opt.code}>{opt.name}</option>
                                        })} 
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.package && errors.package ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.package && errors.package}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='amount' className="block mb-2 text-[#909090] text-xs">
                            Amount
                            </label>
                            <input
                                type="text"
                                name="amount"
                                disabled
                                placeholder="Enter an amount"
                                className={
                                touched.amount && errors.amount
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='smartcardnumber' className="block mb-2 text-[#909090] text-xs">
                            Smart Card Number
                            </label>
                            <input
                                type="text"
                                name="smartcardnumber"
                                placeholder="Enter your smart card number"
                                className={
                                touched.smartcardnumber && errors.smartcardnumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.smartcardnumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.smartcardnumber && errors.smartcardnumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.smartcardnumber && errors.smartcardnumber}
                                </small>
                            ) : null}
                            </div>

                         <div className='mt-6'>
                            <label htmlFor='phonenumber' className="block mb-2 text-[#909090] text-xs">
                            Mobile Number
                            </label>
                            <input
                                type="text"
                                name="phonenumber"
                                placeholder="Enter your mobile number"
                                className={
                                touched.phonenumber && errors.phonenumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.phonenumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.phonenumber && errors.phonenumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.phonenumber && errors.phonenumber}
                                </small>
                            ) : null}
                            </div>

                         <div className="mt-10">
                            <button
                                type="submit"
                                disabled={purchaseLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Buy Cable
                            </button>
                            </div>                        
                        </Form>
                        )}
                    </Formik>
             </div>            
        </Modal>

           {/* Buy Electricity Modal */}
           <Modal show={showElectricityModal} showModal={ElectricityModal} 
         title="Buy Electricity"
         >
            <div>
              <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleBuyElectricity(values, setSubmitting)
                        }
                        validationSchema={buyElectricityValidator}
                        initialValues={{
                        wallet: '',
                        provider: '',
                        amount: '',
                        meternumber: '',
                        metertype: '',
                        phonenumber: ''
                     }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='wallet' className="block mb-2 text-[#909090] text-xs">
                             Select Wallet
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="wallet"
                                    placeholder="Select Wallet"
                                    className='appearance-none w-full cursor-pointer 
                                    disabled:text-red-100
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px]  bg-[#F7F9FB] py-4 px-4'   
                                        value={values.wallet}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                >
                                    <option value="" selected disabled>Select a wallet</option>
                                    <option value="NGN">Naira (NGN)</option>
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                              <small className='text-[#212121] block font-[300] text-[10px] pt-[4px]'>
                                <i
                                    className="mdi mdi-information mr-1"
                                    style={{ color: "#FF8400" }}
                                ></i>
                                Available for only Naira Wallet
                                </small>
                            {touched.wallet && errors.wallet ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.wallet && errors.wallet}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='provider' className="block mb-2 text-[#909090] text-xs">
                            Select Provider
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="provider"
                                    placeholder="Select network provider"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px] bg-[#F7F9FB] py-4 px-4'
                                    value={values.provider}
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                    onBlur={handleBlur}   
                                >
                                    <option value="" selected={values.provider === "" } disabled>Select provider</option>
                                    <option value="Abuja" >ABUJA</option>
                                    <option value="Eko">EKO</option>
                                    <option value="Enugu">ENUGU</option>
                                    <option value="Jos">JOS</option>
                                    <option value="Ibadan">IBADAN</option>
                                    <option value="Ikeja">IKEJA</option>
                                    <option value="Kaduna">KADUNA</option>
                                    <option value="Kano">KANO</option>
                                    <option value="Porthacourt">PORTHACOURT</option>
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.provider && errors.provider ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.provider && errors.provider}
                                </small>
                            ) : null}
                            </div>
                            <div className='mt-6'>
                            <label htmlFor='metertype' className="block mb-2 text-[#909090] text-xs">
                            Meter Type
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="metertype"
                                    placeholder="Select network provider"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px] bg-[#F7F9FB] py-4 px-4'
                                    value={values.metertype}
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                    onBlur={handleBlur}   
                                >
                                    <option value="" selected={values.metertype === "" } disabled>Select meter type</option>
                                    <option value="PREPAID" >PREPAID</option>
                                    <option value="POSTPAID">POSTPAID</option>                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.metertype && errors.metertype ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.metertype && errors.metertype}
                                </small>
                            ) : null}
                            </div>
                        
                         <div className='mt-6'>
                            <label htmlFor='meternumber' className="block mb-2 text-[#909090] text-xs">
                            Meter Number
                            </label>
                            <input
                                type="text"
                                name="meternumber"
                                placeholder="Enter your meter number"
                                className={
                                touched.meternumber && errors.meternumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.meternumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.meternumber && errors.meternumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.meternumber && errors.meternumber}
                                </small>
                            ) : null}
                            </div>

                        <div className='mt-6'>
                            <label htmlFor='amount' className="block mb-2 text-[#909090] text-xs">
                            Amount
                            </label>
                            <input
                                type="text"
                                name="amount"
                                placeholder="Enter an amount"
                                className={
                                touched.amount && errors.amount
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            </div>

                         <div className='mt-6'>
                            <label htmlFor='phonenumber' className="block mb-2 text-[#909090] text-xs">
                            Mobile Number
                            </label>
                            <input
                                type="text"
                                name="phonenumber"
                                placeholder="Enter your mobile number"
                                className={
                                touched.phonenumber && errors.phonenumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.phonenumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.phonenumber && errors.phonenumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.phonenumber && errors.phonenumber}
                                </small>
                            ) : null}
                            </div>

                         <div className="mt-10">
                            <button
                                type="submit"
                                disabled={purchaseLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Buy Electricity
                            </button>
                            </div>                        
                        </Form>
                        )}
                    </Formik>
             </div>            
        </Modal>

           {/* Buy Wifi Modal */}
           <Modal show={showWifiModal} showModal={WifiModal} 
         title="Buy Wifi"
         >
            <div>
              <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleBuyWifi(values, setSubmitting)
                        }
                        validationSchema={buyWifiValidator}
                        initialValues={{
                        wallet: '',
                        provider: '',
                        wifiplan: '',
                        amount: '0',
                        phonenumber: ''
                     }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor='wallet' className="block mb-2 text-[#909090] text-xs">
                             Select Wallet
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="wallet"
                                    placeholder="Select Wallet"
                                    className='appearance-none w-full cursor-pointer 
                                    disabled:text-red-100
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px]  bg-[#F7F9FB] py-4 px-4'   
                                        value={values.wallet}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                >
                                    <option value="" selected disabled>Select a wallet</option>
                                    <option value="NGN">Naira (NGN)</option>
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                              <small className='text-[#212121] block font-[300] text-[10px] pt-[4px]'>
                                <i
                                    className="mdi mdi-information mr-1"
                                    style={{ color: "#FF8400" }}
                                ></i>
                                Available for only Naira Wallet
                                </small>
                            {touched.wallet && errors.wallet ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.wallet && errors.wallet}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='provider' className="block mb-2 text-[#909090] text-xs">
                            Select Wifi Provider
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="provider"
                                    placeholder="Select network provider"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px] bg-[#F7F9FB] py-4 px-4'
                                    value={values.provider}
                                    onChange={(e) => {
                                        handleChange(e)
                                        handleWifiPlan(e.currentTarget.value)
                                        setFieldValue('wifiplan', "")
                                        setFieldValue('amount', "0")
                                        setFieldTouched('provider', false)
                                    }}
                                    onBlur={handleBlur}   
                                >
                                    <option value="" selected={values.provider === "" } disabled>Select wifi provider</option>
                                    <option value="smile" >SMILE</option>
                                    <option value="spectranet">SPECTRANET</option>                                
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.provider && errors.provider ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.provider && errors.provider}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='wifiplan' className="block mb-2 text-[#909090] text-xs">
                            Select Wifi Plan
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="wifiplan"
                                    placeholder="Select network provider"
                                    className='appearance-none w-full cursor-pointer 
                                    placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121]
                                        text-sm  border-none focus:outline-none rounded-[4px] bg-[#F7F9FB] py-4 px-4'
                                    value={values.wifiplan}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setFieldValue('amount',wifiplans.find((pro) => pro.code === e.currentTarget.value).amount)
                                        setFieldTouched('wifiplan', false)
                                    }}
                                    onBlur={handleBlur}   
                                >
                                    <option value="" selected={values.wifiplan === "" } disabled>Select wifi plan</option>
                                    {wifiplans.map((opt, index) => {
                                        return <option key={index} value={opt.code}>{opt.name}</option>
                                        })}                            
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.wifiplan && errors.wifiplan ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.wifiplan && errors.wifiplan}
                                </small>
                            ) : null}
                            </div>
    
                        <div className='mt-6'>
                            <label htmlFor='amount' className="block mb-2 text-[#909090] text-xs">
                            Amount
                            </label>
                            <input
                                type="text"
                                name="amount"
                                disabled
                                placeholder="Enter an amount"
                                className={
                                touched.amount && errors.amount
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            </div>

                         <div className='mt-6'>
                            <label htmlFor='phonenumber' className="block mb-2 text-[#909090] text-xs">
                            Mobile Number
                            </label>
                            <input
                                type="text"
                                name="phonenumber"
                                placeholder="Enter your mobile number"
                                className={
                                touched.phonenumber && errors.phonenumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.phonenumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.phonenumber && errors.phonenumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.phonenumber && errors.phonenumber}
                                </small>
                            ) : null}
                            </div>

                         <div className="mt-10">
                            <button
                                type="submit"
                                disabled={purchaseLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Buy Wifi
                            </button>
                            </div>                        
                        </Form>
                        )}
                    </Formik>
             </div>            
        </Modal>

        {/* withdraw funds modal */}
        <Modal show={showWithdrawModal} showModal={WithdrawModal} 
         title="Withdraw Funds"
         >
            <div className='mt-10'>
                 <ul className='flex'>
                            {
                        types.map((val)=>(
                            <li key={val.id} onClick={() => toggleTag(val.id)} 
                                className={
                                    tagId === val.id ?
                                    "border-b-[#235643] border-b-4 flex-1 cursor-pointer"
                                    :
                                    "flex-1 border-b-[#D9D9D9] border-b-4 cursor-pointer"
                                }
                                >
                            <p className={
                                tagId === val.id
                                ?
                                "text-[#235643] font-Satoshi500 text-sm text-center mb-1"
                                :
                                "text-[#D9D9D9] font-Satoshi500 text-sm text-center mb-1"
                            }
                            >{val.name}</p>
                        </li>
                        ))  
                        }
                 </ul>     

                {/* withdraw funds */}
                {
                    tagId === 1 &&

                    <div className='mt-6'>
                      <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        withdrawFunds(values, setSubmitting)
                        }
                        validationSchema={withdrawalAccountValidator}
                        initialValues={{
                            wallet: '',
                            withdrawalAcct: '',
                            amount: ''
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                         <div>
                            <label htmlFor='wallet' className="block mb-2 text-[#909090] text-xs">
                             Select Wallet
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="wallet"
                                    placeholder="Select Wallet"
                                    className={
                                        touched.wallet && errors.wallet
                                            ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                            : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                        }
                                        value={values.wallet}
                                        onChange={(e) => {
                                            handleChange(e);
                                            fetchWithdrawalAccts(e.currentTarget.value);
                                          }}
                                        onBlur={handleBlur} 
                                      
                                >
                                    <option value="" selected disabled>Select a wallet</option>
                                    <option value="NGN">Naira (NGN)</option>
                                    <option value="GHS">Ghana (GHS)</option>
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.wallet && errors.wallet ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.wallet && errors.wallet}
                                </small>
                            ) : null}
                           
                        </div>

                        <div className='mt-6'>
                        <label htmlFor='withdrawalAcct' className="block mb-2 text-[#909090] text-xs">
                        Withdrawal Account
                        </label>
                        <div className='relative'>
                                <select
                                    type="text"
                                    name="withdrawalAcct"
                                    placeholder="Select a withdrawal account"
                                    className={
                                        touched.withdrawalAcct && errors.withdrawalAcct
                                            ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                            : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                        }
                                        value={values.withdrawalAcct}
                                        onChange={handleChange}
                                        onBlur={handleBlur}   
                                      
                                >
                                    <option value="" selected disabled>Select a withdrawal account</option>
                                    {withdrawalAccounts.map((opt, index) => {
                                        return <option key={index} value={opt.id}>{opt.bankname} - {opt.accountname}</option>
                                     })} 
                                        
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.withdrawalAcct && errors.withdrawalAcct ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.withdrawalAcct && errors.withdrawalAcct}
                                </small>
                            ) : null}
                        </div>

                        <div className='mt-6'>
                        <label htmlFor='amount' className="block mb-2 text-[#909090] text-xs">
                            Amount
                        </label>
                            <input
                                type="text"
                                name="amount"
                                placeholder="Enter an amount"
                                className={
                                    touched.amount && errors.amount
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                    }
                                    value={values.amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}  
                            />
                            {touched.amount && errors.amount ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.amount && errors.amount}
                                </small>
                            ) : null}
                            
                        </div>

                        <div className='mt-6 flex justify-center'>
                            <div className='flex gap-2 items-center cursor-pointer' onClick={toggleAddAccount}>
                                <div className='bg-[#235643] w-[20px] h-[20px] flex justify-center items-center rounded-full bg-opacity-[0.4]'>
                                  <img src={add_green} alt="add green" />
                                </div>
                                <div>
                                     <h6 className='text-[#235643] font-Satoshi400 text-sm'>Add new account</h6>
                                </div>       
                            </div>
                        </div>

                        <div className="mt-10">
                            <button
                                type="submit"
                                disabled={withdrawLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Withdraw Funds
                            </button>
                         </div> 
                         </Form>
                        )}
                    </Formik> 

                    </div>
                }

                {/* withdraw usdt */}
                {/* {
                    tagId === 2 &&
                    <div className='mt-6'>
                        <div>
                             <label className="block mb-2 text-[#909090] text-xs">
                              Check Conversion
                            </label> 
                            <div className='bg-[#F7F9FB] rounded-lg py-4 px-4'>
                                <div className='flex justify-between items-end'>
                                    <div>
                                        <label className='text-[#909090] font-Satoshi500 text-xs'>Amount</label>
                                        <input
                                            type="number"
                                            name="amount"
                                            placeholder='50000'
                                            value={amount}
                                            onChange={(e) => {
                                                handleAmount(e.target.value)
                                            }}
                                            className='appearance-none w-full mt-1 placeholder:text-[#B9B9B9] text-2xl text-[#212121]  border-none focus:outline-none rounded-md  bg-transparent'
                                        />
                                    </div>
                                    <div className="relative">
                                         <div 
                                          onClick={toggleUSDTCurrency}
                                         className='bg-[#E9E9E9] cursor-pointer w-max rounded-lg py-3 px-4 flex items-center gap-4 h-fit'>
                                            <div className='flex gap-2 items-center'>
                                                <img src={cryptoCurrency === "NGN" ? flag_nigeria : flag_ghana} alt="flag" />
                                                <p className='text-[#212121] font-Satoshi400 text-sm'>
                                                    {cryptoCurrency}
                                                </p>
                                            </div>
                                            <div className='cursor-pointer'>
                                                <img src={chevron_down_black} alt="chevron down" />
                                            </div>
                                        </div>

                                      <div 
                                        className={
                                            showUSDTMenu ?
                                            'absolute z-[12] visible top-[55px] bg-[#E9E9E9] rounded-lg h-fit w-full transition-all ease-in-out duration-[0.3s]'
                                            :
                                            'absolute z-[12] hidden top-[70px] bg-[#E9E9E9] rounded-lg h-fit w-full transition-all ease-in-out duration-[0.3s]'
                                        }
                                        >
                                        <div 
                                         onClick={handleUSDTNigeria} 
                                        className='cursor-pointer  py-3 px-4 flex items-center gap-4 hover:bg-[#FAC915] hover:rounded-tl-md hover:rounded-tr-md'>
                                            <div className='flex gap-2 items-center'>
                                                <img src={flag_nigeria} alt="flag" />
                                                <p className='text-[#212121] font-Satoshi400 text-sm'>NGN</p>
                                            </div>
                                            <div className='cursor-pointer'>
                                            </div>
                                        </div>

                                        <div 
                                           onClick={handleUSDTGhana} 
                                        className='cursor-pointer py-3 px-4 flex items-center gap-4 hover:bg-[#FAC915] hover:rounded-bl-md hover:rounded-br-md'>
                                            <div className='flex gap-2 items-center'>
                                                <img src={flag_ghana} alt="flag" />
                                                <p className='text-[#212121] font-Satoshi400 text-sm'>GHS</p>
                                            </div>
                                            <div className='cursor-pointer'>
                                            </div>
                                        </div>

                                      </div>
                                    </div>
                                </div>


                                <div className='bg-[#C2C2C2] w-full h-[1px] mt-8 relative'>
                                    <div className='bg-[#235643] w-[36px] h-[36px] rounded-full 
                                    flex justify-center items-center absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                                            <img src={reverse_icon} alt="reverse icon" />
                                        </div>
                                </div>
                                 

                                <div className='mt-8'>
                                    <label className='text-[#909090] font-Satoshi500 text-xs'>USDT Equivalent</label>
                                    <div className='flex gap-1 items-center mt-1'>
                                        <p className='text-2xl text-[#212121]'>$</p>
                                        <input
                                            type="number"
                                            name="usdtamount"
                                            placeholder='50000'
                                            value={usdtAmount}
                                            onChange={(e) => {
                                                handleUSDTAmount(e.target.value)
                                            }}
                                            className='appearance-none w-full placeholder:text-[#B9B9B9] text-2xl text-[#212121]  border-none focus:outline-none rounded-md  bg-transparent'
                                        />
                                    </div>
                                  
                                </div>
                            </div>   
                        </div>

                      <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        withdrawFundsAsUSDt(values, setSubmitting)
                        }
                        validationSchema={withdrawUSDTAccountValidator}
                        initialValues={{
                            wallet_address: '',
                            network: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                        <div className='mt-6'>
                            <label htmlFor='wallet_address' className="block mb-2 text-[#909090] text-xs">
                            USDT Wallet Address
                            </label>
                            <div className='relative'>
                            <input
                                type="text"
                                name="wallet_address"
                                placeholder="1FfmbHfnpaZjKFvyi1okTjJJusN455paPH"
                                className={
                                    touched.wallet_address && errors.wallet_address
                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                    }
                                    value={values.wallet_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}  
                             />
                               {touched.wallet_address && errors.wallet_address ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.wallet_address && errors.wallet_address}
                                </small>
                            ) : null}
                            </div>
                         
                        </div>

                        <div className='mt-6'>
                            <label htmlFor='network' className="block mb-2 text-[#909090] text-xs">
                            Network
                            </label>
                            <div className='relative'>
                                <select
                                    type="text"
                                    name="network"
                                    placeholder="Select Network"
                                    className={
                                        touched.network && errors.network
                                            ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                            : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                        }
                                        value={values.network}
                                        onChange={handleChange}
                                        onBlur={handleBlur}  
                                >
                                    <option value="" selected disabled>Select a network</option>
                                    {networks.map((opt, index) => {
                                             return <option key={index} value={opt}>{opt}</option>
                                        })}  
                                </select>
                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            {touched.network && errors.network ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.network && errors.network}
                                </small>
                            ) : null}
                        </div>

                        <div className="mt-10">
                            <button
                                type="submit"
                                disabled={withdrawLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Withdraw USDT
                            </button>
                         </div>  

                         </Form>
                        )}
                    </Formik> 


                             
                    </div>
                } */}


             </div>            
        </Modal>

        {/* add account modal */}
        <Modal show={showAccountModal} showModal={AccountModal} 
         title="Add Account"
         >
            <div>
              <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        addAccountInfo(values, setSubmitting)
                        }
                        validationSchema={currency === 'NGN' ? bankAccountValidatorNG : bankAccountValidatorGH}
                        initialValues={{
                        accountNumber: '',
                        bank: '',
                        }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-10" onSubmit={handleSubmit}>
                            <div className='mt-6'>
                            <label htmlFor='accountNumber' className="block mb-2 text-[#909090] text-xs">
                            Account Number
                            </label>
                            <input
                                type="text"
                                name="accountNumber"
                                placeholder="Enter an account number"
                                className={
                                touched.accountNumber && errors.accountNumber
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.accountNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleVerifyAcct(e.currentTarget.value,values.bank);
                                  }}
                                onBlur={handleBlur}
                            />
                            {touched.accountNumber && errors.accountNumber ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.accountNumber && errors.accountNumber}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='bank' className="block mb-2 text-[#909090] text-xs">
                            Select Bank
                            </label>
                            <div className='relative'>
                            <select
                                type="text"
                                name="bank"
                                placeholder="Select a bank"
                                className={
                                touched.bank && errors.bank
                                    ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.bank}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleBank(e.currentTarget.value,values.accountNumber);
                                  }}
                                onBlur={handleBlur}
                            >
                             <option value="" selected={values.bank === "" } disabled>Select a bank</option>
                            {banks.map((opt, index) => {
                                return <option key={index} value={opt.code}>{opt.name}</option>
                                })} 
                            </select>
                                 <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                            </div>
                            
                            {touched.bank && errors.bank ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.bank && errors.bank}
                                </small>
                            ) : null}
                            </div>

                            <div className='mt-6'>
                            <label htmlFor='accountname' className="block mb-2 text-[#909090] text-xs">
                            Account Name
                            </label>
                            <input
                                type="text"
                                name="accountname"
                                placeholder="Account Name"
                                disabled={currency === 'NGN' ? true : false}
                                className='appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                value={currency === "NGN" ? accountName : GHAccountName}
                                onChange={(e) => handleAccountName(e.target.value)}
                                onBlur={handleBlur}
                            />
                            </div>

            
                          <div className="mt-10">
                            <button
                                type="submit"
                                disabled={addLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Add Account
                            </button>
                            </div>                        
                        </Form>
                        )}
                    </Formik>
             </div>            
        </Modal>

        {/* Transaction PIN */}
         <Modal show={showPINModal} showModal={PINModal} 
         title=""
         >
            <div className='mt-10'>
                <p className='text-[#212121] font-Satoshi500 text-[15px] text-center'>
                Enter your transaction PIN
                </p>

                <div className='text-center mt-6'>
                         <PinInput
                            length={4}
                            initialValue={pin}
                            focus
                            secret
                            type="numeric"
                            onChange={handlePin}
                            inputStyle={{ borderColor: "#CCCCCC", color: '#212121' }}
                            inputFocusStyle={{ borderColor: "#235643" }}
                        />
                         <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]" >
                                 {valid ? "Incorrect Pin!": ""}
                          </small>
                  </div>

                <div className="mt-10">
                    <button
                        type="submit"
                        disabled={confirmWithdrawLoader}
                        onClick={confirmWithdrawal}
                        className="bg-[#235643] 
                        disabled:bg-[#235643] 
                        disabled:opacity-[0.7]
                        text-sm
                        rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                    >
                        Withdraw
                    </button>
                 </div> 
                
             </div>            
        </Modal>

             {/* set transaction pin */}
        <Modal show={showTransactionPINModal} hideClose showModal={TransactionPINModal}
            title="Create a 4-digit PIN"
            >
                <div className='mt-10'>
                  
                     <div className='mt-6 text-center'>
                          <label className="block mb-2 text-[#909090] text-xs">
                             Create a 4-digit PIN.
                           
                         </label>
                         <div className=''>
                            <PinInput
                                length={4}
                                initialValue={newpin}
                                focus
                                secret
                                type="numeric"
                                onChange={handleNewPin}
                                inputStyle={{ borderColor: "#CCCCCC", color: '#212121' }}
                                inputFocusStyle={{ borderColor: "#235643" }}
                            />
                         </div>
                    </div>

                    <div className="mt-6 text-center">
                     <label className="block mb-2 text-[#909090] text-xs">
                          Confirm your 4-digit PIN
                        </label>
                        <div className=''>
                            <PinInput
                                length={4}
                                initialValue={confirmpin}
                                
                                secret
                                type="numeric"
                                onChange={handleConfirmPin}
                                inputStyle={{ borderColor: "#CCCCCC", color: '#212121' }}
                                inputFocusStyle={{ borderColor: "#235643" }}
                            />
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]" >
                                    {pinValid ? "Pin must match!" : ''}
                            </small>
                         </div>
                    </div>
                   

                    <div className="mt-10">
                        <button
                            type="submit"
                            onClick={setTransactPin}
                            disabled={setPinLoader}
                            className="bg-[#235643] 
                            disabled:bg-[#235643] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                        >
                            Set Transaction PIN
                        </button>
                    </div>   
                </div>            
            </Modal>


           {/* Transaction Details Modal */}
           <Modal show={showTransactionModal} showModal={TransactionModal} 
         title="Transaction Details"
         >
            <div className='mt-10 grid md:grid-cols-2 gap-4'>
               <div>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Reference</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.reference ? transaction.reference: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Transaction Date</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.created_at ? moment(transaction.created_at).format('MMMM Do YYYY, h:mm:ss a'): ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Status</p>
                            <div className='border border-[#39A307] text-[#39A307] w-fit text-sm font-Satoshi400 rounded-[4px] flex justify-center bg-transparent px-4 py-1 mt-2'>
                            {transaction && transaction.status ? capitalizeFirstLetterFormat(transaction.status): ''}
                            </div>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Last Updated</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>
                            {transaction && transaction.created_at ? moment(transaction.updated_at).format('MMMM Do YYYY, h:mm:ss a'): ''}
                            </h6>
                        </div>
                    </div>
                    
                    <h5 className='text-[#235643] font-Satoshi400 text-sm mt-6'>TRANSACTION FEEDBACK</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Admin’s Feedback</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>
                            {transaction && transaction.admin_comment ? 
                            transaction.admin_comment == null ? 'NIL' : transaction.admin_comment : ''}
                            </h6>
                        </div>
                        
                    </div>

                    <h5 className='text-[#235643] font-Satoshi400 text-sm mt-6'>UPLOADED IMAGES</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1'>
                        <div className='flex gap-2 items-center'>
                        {
                            transaction && transaction.images ?
                            transaction.images.map((url, index)=>(
                             <Zoom>
                              <img src={url} alt="card_sample" className='w-[32px] h-[32px] rounded-md' />
                            </Zoom>
                            ))
                            :
                            <div className='text-center '>
                                <p className='text-[#212121] text-xs font-Satoshi500'>No cards uploaded!</p>
                            </div>
                           }
                        </div>
                        
                    </div>
               </div>

               <div>
                     <h5 className='text-[#235643] font-Satoshi400 text-sm'>TRANSACTION INFO</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Transaction type</p>
                            <div className='border border-[#39A307] text-[#39A307] uppercase w-fit text-sm font-Satoshi400 rounded-[4px] flex justify-center bg-transparent px-6 py-1 mt-2'>
                            {transaction && transaction.type ? transaction.type: ''}
                            </div>
                        </div>
                        {transaction && transaction.type !== 'buy' &&  
                         <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Gift Card</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.card ? transaction.card.category_name: ''}</h6>
                        </div>
                        }
                           { transaction && transaction.type !== 'buy' && <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Rate</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'> {transaction && transaction.card ? transaction.card.rate: ''}/$</h6>
                        </div>
                        }
                          <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Description</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction ? transaction.description: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Unit</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>${transaction && transaction.unit ? transaction.unit: ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Total Amount</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{currency === "NGN" ? '₦' : '₵'}{transaction && transaction.amount ? numberWithCommas(transaction.amount) : ''}</h6>
                        </div>
                        <div className='mt-4'>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>User’s Comment</p>
                            <h6 className='text-[#212121] font-Satoshi500 text-sm mt-1'>{transaction && transaction.comment ? transaction.comment: ''}</h6>
                        </div>
                        
                        
                    </div>

                    { transaction && transaction.type === 'buy' && 
                    <>
                     <h5 className='text-[#235643] font-Satoshi400 text-sm'>GIFT CARD DETAILS</h5>
                    <div className='bg-[#F9F9F9] rounded-lg py-4 px-4 mt-1 mb-4'>
                        <div>
                            <p className='text-[#909090] font-Satoshi500 text-xs'>Card PIN/Code</p>
                                <ol className='mt-2 ml-4 list-disc'>
                                    {
                                        transaction && transaction.redeem_code ? transaction.redeem_code.map((val)=>(
                                        <li className='text-[#212121] font-Satoshi500 text-sm mt-1'>{val.cardNumber !== null ? val.cardNumber : val.pinCode}</li>
                                        ))
                                        :
                                        ""
                                    }
                                  
                                </ol>
                        </div>            
                    </div>
                    </>
                    }
               </div>
                
             </div>            
        </Modal>

        {/* VBA Info Modal */}
        <Modal show={showVBAInfoModal} showModal={VBAInfoModal} 
         title=""
         >
            <div className='mt-10 flex items-center gap-4 md:gap-8'>
                <div className="flex-shrink-0">
                    <img src={vba_img} alt="vba" />
                </div>
                <div>
                    <h2 className="text-[#212121] font-Satoshi500 text-xl md:text-2xl">The Cardvest <br/> Virtual Bank Account</h2>
                    <ol className="mt-4 list-disc">
                        <li className='text-[#212121] font-Satoshi400 text-sm mb-1'>Get a unique virtual bank account for easy wallet funding. </li>
                        <li className='text-[#212121] font-Satoshi400 text-sm mb-1'>Requesting takes less than 5 minutes.</li>
                        <li className='text-[#212121] font-Satoshi400 text-sm'>It’s free of charge.</li>
                    </ol>
                </div>
             </div>  
             <div className="mt-5 flex justify-center">
                <h6 className="text-[#909090] font-Satoshi400 text-sm">To create your virtual bank account, we need some information about you.</h6>
             </div> 
             <div className="mt-6 flex justify-center">
                {
                    banking_verified
                    ?
                    <button
                        type="submit"
                        disabled={vbaLoader}
                        onClick={handleRequestVBA}
                        className="bg-[#235643] 
                        disabled:bg-[#235643] 
                        disabled:opacity-[0.7]
                        text-sm
                        rounded-md px-16 py-4 text-[#fff] font-Satoshi400 
                        hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                    >
                        Request Virtual Account
                    </button>
                    :
                    <button
                    onClick={toggleVerifyKYCModal}
                        type="submit"
                        className="bg-[#235643] 
                        disabled:bg-[#235643] 
                        disabled:opacity-[0.7]
                        text-sm
                        rounded-md px-16 py-4 text-[#fff] font-Satoshi400 
                        hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                    >
                        Verify KYC
                    </button>

                }
               
            </div>         
        </Modal>

        {/* verify kyc modal */}
        <Modal show={showVerifyKYCModal} showModal={VerifyKYCModal} 
         title="Identity Verification"
         >
            <div className="mt-8 max-w-sm mx-auto text-center">
                <h6 className="text-[#212121] text-sm font-Satoshi400">Please provide some information about yourself. <br/>We use this information to protect your account, and for compliance purposes. </h6>
                <p className="text-[#212121] text-sm font-Satoshi400 mt-4">The full name must be as shown on your BVN.</p>
            </div>

            <div>
              <Formik
                        onSubmit={(values, { setSubmitting }) =>
                        handleVerifyKYC(values, setSubmitting)
                        }
                        enableReinitialize={true}
                        validationSchema={verifyKYCValidator}
                        initialValues={{
                        bvn: '',
                        firstname: userInfo && userInfo.firstname ? userInfo.firstname: '',
                        lastname: userInfo && userInfo.lastname ? userInfo.lastname: '',
                     }}
                    >
                        {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        }) => (
                        <Form className="mt-8" onSubmit={handleSubmit}>
                        <div className=''>
                            <label htmlFor='firstname' className="block mb-2 text-[#909090] text-xs">
                            First Name
                            </label>
                            <input
                                type="text"
                                name="firstname"
                                placeholder="Enter your First Name"
                                className={
                                touched.firstname && errors.firstname
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.firstname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.firstname && errors.firstname ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.firstname && errors.firstname}
                                </small>
                            ) : null}
                            </div>

                         <div className='mt-6'>
                            <label htmlFor='lastname' className="block mb-2 text-[#909090] text-xs">
                            Last Name 
                            </label>
                            <input
                                type="text"
                                name="lastname"
                                placeholder="Enter your Last Name"
                                className={
                                touched.lastname && errors.lastname
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.lastname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.lastname && errors.lastname ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.lastname && errors.lastname}
                                </small>
                            ) : null}
                            </div>


                            <div className='mt-6'>
                            <label htmlFor='phonenumber' className="block mb-2 text-[#909090] text-xs">
                            BVN 
                            </label>
                            <input
                                type="text"
                                name="bvn"
                                placeholder="Enter your BVN"
                                className={
                                touched.bvn && errors.bvn
                                    ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                    : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                }
                                value={values.bvn}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.bvn && errors.bvn ? (
                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                    {touched.bvn && errors.bvn}
                                </small>
                            ) : null}
                            </div>

                            <div onClick={BVNInfoModal} className='mt-6 flex justify-center items-center gap-2 cursor-pointer'>
                                <div className="bg-[#2356431F] w-[18px] h-[18px] rounded-full flex items-center justify-center">
                                    <img src={question_icon} alt="question icon" />
                                </div>
                                <div>
                                    <p className="text-[#235643] text-sm font-Satoshi400">Why do you need my BVN?</p>
                                </div>

                            </div>

                         <div className="mt-10">
                            <button
                                type="submit"
                                disabled={kycLoader}
                                className="bg-[#235643] 
                                disabled:bg-[#235643] 
                                disabled:opacity-[0.7]
                                text-sm
                                rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                            >
                                Verify KYC
                            </button>
                            </div>                        
                        </Form>
                        )}
                    </Formik>
             </div>            
        </Modal>

        {/* why do you need my bvn modal */}
        <Modal show={showBVNInfoModal} showModal={BVNInfoModal} 
         title=""
         >
            <div className="mt-8 flex justify-center">
                <img src={shield_icon} alt="shield" />
            </div>
            <div className="mt-10">
                <h4 className="text-[#235643] font-Satoshi700 text-base">Why do you need my BVN?</h4>
                <p className="mt-5 text-[#212121] text-sm font-Satoshi400">
                Your BVN is required for compliance purposes, to increase your withdrawal limit, and for you to have access to more features e.g a virtual bank account. 
                </p> 
                <p className="mt-5 text-[#212121] text-sm font-Satoshi400">
                Please note that your BVN is never shared and it does not give us access to your bank account. Your details are safe with us.
             </p>
           </div>

             <div className="mt-10">
                <button
                onClick={() =>setShowBVNInfoModal(false)}
                    type="submit"
                    className="bg-[#235643] 
                    disabled:bg-[#235643] 
                    disabled:opacity-[0.7]
                    text-sm
                    rounded-md w-full py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                >
                    Continue
                </button>
                </div>      
        
        </Modal>

        {/* identity verified modal */}
        <Modal show={showIdentityModal} showModal={IdentityVerifyModal} 
         title=""
         >
            <div className="mt-8 flex justify-center">
                <img src={shield_check} alt="shield" />
            </div>
            <div className="mt-10 text-center">
                <h4 className="text-[#235643] font-Satoshi500 text-xl">Identity Verified!</h4>
                <p className="mt-2 text-[#909090] text-sm font-Satoshi400">
                Your identity has been verified successfully
                </p>
           </div>

             <div className="mt-8 flex justify-center">
                <button
                    disabled={vbaLoader}
                    onClick={handleRequestVBA}
                    type="submit"
                    className="bg-[#235643] 
                    disabled:bg-[#235643] 
                    disabled:opacity-[0.7]
                    text-sm
                    rounded-md px-12 py-4 text-[#fff] font-Satoshi400 hover:bg-[#235643] hover:opacity-[0.9] transition-all"
                >
                    Request Virtual Account
                </button>
            </div> 
            <div className="text-center mt-4">
               <p onClick={IdentityVerifyModal} className="mt-4 text-[#235643] text-sm font-Satoshi400 underline cursor-pointer">Go to Dashboard</p>
            </div>     
        
        </Modal>

        {/* fund wallet modal */}
        <Modal show={showFundWalletModal} showModal={FundWalletModal} 
         title="Fund Wallet"
         >
           <div className="max-w-md mx-auto">
            <div className="mt-10">
                <div className="flex justify-center">
                    <div>
                        <p className="text-[#212121] text-center text-sm font-Satoshi400">{bankDetails ? bankDetails.accountname: 'Cardvest Account Number'}</p>

                        <div className="flex gap-2 items-center mt-1">
                            <h4 className="text-[#212121] font-Satoshi500 text-2xl md:text-4xl">{bankDetails ? bankDetails.banknumber: '0122301448'}</h4>
                            <CopyToClipboard text={bankDetails ? bankDetails.banknumber: '0122301448'}
                                     onCopy={() => handleCopy()}>
                                    <img src={copy_icon} alt="copy icon" className="cursor-pointer" />
                                     </CopyToClipboard>
                        </div>

                        <p className="text-[#212121] mt-1 text-center text-sm font-Satoshi400">
                        {bankDetails ? bankDetails.bankname: 'Bank Name'}
                        </p>
                    </div>
                </div>
            
                <p className="mt-5 text-center text-[#909090] text-xs font-Satoshi400">
                Money transferred to this bank account number will automatically top up your CardVest wallet. Receive funds from only bank account that matches this account name, Any funds sent from other accounts would be flagged.
                 </p>
           </div>

           <div className="mt-5 flex justify-center">
              <p className="text-center text-[#212121] text-sm font-Satoshi500">
              Deposits below ₦2k cost ₦20, while others above ₦2k incur a 1% fee
                 </p>
            </div>

             {/* <div className="mt-8">
                <button
                    type="submit"
                    className="bg-white 
                    disabled:bg-[#235643] 
                    disabled:opacity-[0.7]
                    text-sm border border-[#235643]
                    rounded-md w-full py-4 text-[#235643] 
                    font-Satoshi400 hover:bg-[#235643] hover:text-white hover:opacity-[0.9] transition-all"
                >
                    Share Account Details
                </button>
             </div>   */}
            </div>    
        
        </Modal>


            <div>
                <h6 className='text-[#212121] font-Satoshi400 text-sm'>Welcome back, <span className='font-Satoshi700'>{userInfo ? capitalizeFirstLetter(userInfo.username) : 'Anonymous'}!</span></h6>

                <div className='flex md:flex-row flex-col gap-4 mt-6'>
                    <div className='flex-1'>
                        <div className='bg-wallet rounded-xl relative'>
                            <div className='flex flex-col justify-between min-h-[250px] md:min-h-[300px] py-6 px-4 md:px-6'>
                                <div className='mt-8 flex justify-between'>
                                    <div>
                                        <div>
                                            <p className='text-[#EEEEEE] font-Satoshi400 text-sm'>Wallet Balance</p>
                                        </div>
                                        <div className='flex gap-1 items-center mt-1'>
                                            <img src={currency === "NGN" ? naira :cedis} alt="naira" className='w-6 h-6' />
                                            <h4 className='text-white font-Satoshi500 text-3xl'>
                                            {numberWithCommas(walletbalance)}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className='relative'>
                                        <div 
                                        onClick={toggleCurrency}
                                        className='bg-[#336653] cursor-pointer rounded-lg py-3 px-4 flex items-center gap-6 h-fit'>
                                            <div className='flex gap-3 items-center'>
                                                <img src={currency === "NGN"? flag_nigeria : flag_ghana} alt="flag" />
                                                <p className='text-[#FFFFFF] font-Satoshi400 text-sm'>
                                                    {currency}
                                                </p>
                                            </div>
                                            <div className='cursor-pointer'>
                                                <img src={chevron_down} alt="chevron down" />
                                            </div>
                                        </div>

                                      <div 
                                      className={
                                        showCurrencyMenu ?
                                        'absolute z-[12] visible top-[55px] bg-[#336653] rounded-lg h-fit w-full transition-all ease-in-out duration-[0.3s]'
                                        :
                                        'absolute z-[12] hidden top-[70px] bg-[#336653] rounded-lg h-fit w-full transition-all ease-in-out duration-[0.3s]'
                                      }
                                      >
                                            <div
                                              onClick={handleNigeriaCurrency} 
                                            className='flex py-3 px-4 items-center gap-6 cursor-pointer hover:bg-[#235643] hover:rounded-tl-md hover:rounded-tr-md'>
                                                <div className='flex gap-3 items-center'>
                                                    <img src={flag_nigeria} alt="flag nigeria" />
                                                    <p className='text-[#FFFFFF] font-Satoshi400 text-sm'>NGN</p>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                            <div 
                                            onClick={handleGhanaCurrency}
                                            className='flex py-3 px-4 items-center gap-6 cursor-pointer hover:bg-[#235643] hover:rounded-bl-md hover:rounded-br-md'>
                                                <div className='flex gap-3 items-center'>
                                                        <img src={flag_ghana} alt="flag ghana" />
                                                        <p className='text-[#FFFFFF] font-Satoshi400 text-sm'>GHS</p>
                                                 </div>
                                                 <div>
                                        {/* <input id="default-radio-1" type="radio" value="" name="default-radio" className="w-4 h-4 text-white bg-white border-white focus:ring-transparent" /> */}
                                                </div>
                                                   
                                            </div>
                                              
                                        </div>
                                    

                                    </div>

                                    
                                </div>
                                <div className='flex gap-2 md:gap-4 z-10'>
                                  
                                        <button
                                        onClick={WithdrawModal}
                                        className='bg-[#FAC915] rounded-md
                                            disabled:bg-[#FAC915] 
                                            disabled:opacity-[0.7]
                                            text-sm
                                            font-Satoshi500
                                            justify-center
                                            flex items-center gap-1
                                            w-full py-4 px-2 md:px-6 text-[#212121] 
                                            hover:bg-[#FAC915] hover:opacity-[0.9] transition-all'> 
                                                <img src={minus_circle} alt="minus circle" />
                                            Withdraw Funds
                                        </button>

                                        <button
                                        onClick={hasVBA ? FundWalletModal : VBAInfoModal}
                                       className='bg-[#1F523F] rounded-md
                                        disabled:bg-[#1F523F] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Satoshi500 hidden md:flex
                                        justify-center border border-[#FFFFFF4D]
                                         items-center gap-2
                                        w-full py-4 px-2 md:px-6 text-white
                                        hover:bg-[#1F523F] hover:opacity-[0.9] transition-all'> 
                                        <img src={add_circle} alt="add circle" />
                                        <span>Add Funds</span>
                                    </button>
                                    
                                </div>
                            </div>
                            
                            <img src={hand_icon} alt="hand" className="absolute bottom-0 left-0" /> 
                        </div>

                        {/* quick links */}
                        <div className='grid grid-cols-3 md:grid-cols-4 gap-4 md:gap-6 mt-6'>
                            <Link to="/trade-now" className='bg-[#FBFBFB] rounded-md py-4 px-3 cursor-pointer'>
                                <div className='flex justify-center'>
                                    <div className='bg-[#F7F2DD] w-[43px] h-[43px] flex justify-center items-center rounded-full'>
                                        <img src={card_send} alt="card send" />
                                    </div>
                                </div>
                                <div className='mt-2 text-center'>
                                    <p className='text-[#212121[ font-Satoshi400 text-sm'>Sell Giftcard</p>
                                </div>
                            </Link>

                            <Link to="/trade-now" className='bg-[#FBFBFB] rounded-md py-4 px-3 cursor-pointer'>
                                <div className='flex justify-center'>
                                    <div className='bg-[#F7F2DD] w-[43px] h-[43px] flex justify-center items-center rounded-full'>
                                        <img src={card_send} alt="card send" />
                                    </div>
                                </div>
                                <div className='mt-2 text-center'>
                                    <p className='text-[#212121[ font-Satoshi400 text-sm'>Buy Giftcard</p>
                                </div>
                            </Link>

                            <div className='bg-[#FBFBFB] rounded-md py-4 px-3 cursor-pointer'
                             onClick={DataModal}
                            >
                                <div className='flex justify-center'>
                                    <div className='bg-[#F7F2DD] w-[43px] h-[43px] flex justify-center items-center rounded-full'>
                                        <img src={global_icon} alt="global icon" />
                                    </div>
                                </div>
                                <div className='mt-2 text-center'>
                                    <p className='text-[#212121[ font-Satoshi400 text-sm'>Data</p>
                                </div>
                            </div>


                            <div className='bg-[#FBFBFB] rounded-md py-4 px-3 cursor-pointer'
                             onClick={AirtimeModal}
                            >
                                <div className='flex justify-center'>
                                    <div className='bg-[#F7F2DD] w-[43px] h-[43px] flex justify-center items-center rounded-full'>
                                        <img src={airtime_icon} alt="airtime icon" />
                                    </div>
                                </div>
                                <div className='mt-2 text-center'>
                                    <p className='text-[#212121[ font-Satoshi400 text-sm'>Airtime</p>
                                </div>
                            </div>

                            <div className='bg-[#FBFBFB] rounded-md py-4 px-3 cursor-pointer'
                             onClick={ElectricityModal}>
                                <div className='flex justify-center'>
                                    <div className='bg-[#F7F2DD] w-[43px] h-[43px] flex justify-center items-center rounded-full'>
                                        <img src={electricity_icon} alt="electricity icon" />
                                    </div>
                                </div>
                                <div className='mt-2 text-center'>
                                    <p className='text-[#212121[ font-Satoshi400 text-sm'>Electricity</p>
                                </div>
                            </div>

                            <div className='bg-[#FBFBFB] rounded-md py-4 px-3 cursor-pointer'
                             onClick={WifiModal}>
                                <div className='flex justify-center'>
                                    <div className='bg-[#F7F2DD] w-[43px] h-[43px] flex justify-center items-center rounded-full'>
                                        <img src={wifi_icon} alt="wifi icon" />
                                    </div>
                                </div>
                                <div className='mt-2 text-center'>
                                    <p className='text-[#212121[ font-Satoshi400 text-sm'>Wifi</p>
                                </div>
                            </div>

                            <div className='bg-[#FBFBFB] rounded-md py-4 px-3 cursor-pointer'
                             onClick={CableModal}>
                                <div className='flex justify-center'>
                                    <div className='bg-[#F7F2DD] w-[43px] h-[43px] flex justify-center items-center rounded-full'>
                                        <img src={game_icon} alt="game icon" />
                                    </div>
                                </div>
                                <div className='mt-2 text-center'>
                                    <p className='text-[#212121[ font-Satoshi400 text-sm'>Cable</p>
                                </div>
                            </div>
                            {/* <div className='bg-[#FBFBFB] rounded-md py-4 px-3 cursor-pointer'>
                                <div className='flex justify-center'>
                                    <div className='bg-[#F7F2DD] w-[43px] h-[43px] flex justify-center items-center rounded-full'>
                                        <img src={menu_icon} alt="menu icon" />
                                    </div>
                                </div>
                                <div className='mt-2 text-center'>
                                    <p className='text-[#212121[ font-Satoshi400 text-sm'>More</p>
                                </div>
                            </div> */}
                            
                        </div>

                        

                    </div>
                    <div className="flex-1">
                        <div className='bg-[#FBFBFB] rounded-xl py-6 px-4 flex items-center gap-4'>
                                <div>
                                    <p className='text-[#17191C] font-Satoshi700 text-sm'>Transactions</p>
                                </div>
                                  <div className='flex-1'> 
                                    <div className='relative hidden md:block'>
                                        <input
                                            type="text"
                                            name="text"
                                            placeholder="Search transactions"
                                            className="appearance-none
                                            placeholder:text-[#B9B9B9] placeholder:text-[13px]
                                            text-[#212121] text-sm w-full
                                            border-none focus:outline-none rounded-[4px] bg-[#F7F7F7] py-3 px-4"
                                        />
                                        <img src={search_icon} alt="searach icon" className='absolute w-[18px] h-[18px] top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' />
                                     </div>
                                 </div>
                              <div>
                                <Link to="/transactions" className='bg-[#235643] rounded-md
                                    disabled:bg-[#235643] 
                                    disabled:opacity-[0.7]
                                    text-sm
                                    font-Satoshi400
                                    justify-center
                                    flex items-center gap-2
                                    w-full py-3 px-4 text-[#FFFFFF] group
                                    hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                    Show All
                                    <img src={arrow_right} className="group-hover:transition group-hover:duration-300 group-hover:transform group-hover:translate-x-1" alt="arrow right" />
                                </Link>
                              </div>
                        </div>  

                        {/* transactions table */}
                        <div className='bg-[#FBFBFB] mt-4 rounded-xl py-4 px-4'>
                           
                            <div className="overflow-x-auto relative">
                            <table className="w-full text-left">
                                <thead className="text-[#010918] font-Satoshi700 bg-transparent text-xs">
                                <tr>
                                    <th scope="col" className="py-2 px-6">
                                    Type
                                    </th>
                                    <th scope="col" className="py-2 px-6">
                                      Amount
                                    </th>
                                    <th scope="col" className="py-2 px-6">
                                    Date/Time
                                    </th>
                                    <th scope="col" className="py-2 px-6">
                                    Status
                                    </th>
                                </tr>
                            </thead>

                            {!loading && (
                            <tbody>
                                {recentTransactions.length > 0
                                ? recentTransactions.map((item) => (
                              <tr key={item.id}
                              onClick={()=> viewTransaction(item.id, item.reference)}
                               className="bg-white text-xs font-Satoshi400 rounded-lg hover:bg-gray-100 hover:rounded-lg cursor-pointer">
                                <td
                                className="py-3 px-4 text-[#212121] whitespace-nowrap "
                                >
                                    <div className='flex gap-2 items-center'>
                                        <div className = 'flex justify-center items-center w-[36px] h-[36px] bg-white rounded-full'>
                                            <img src={convert_card} alt="card" />
                                        </div>
                                        <span>{capitalizeFirstLetter(item.type === 'sell' ? item.card.category_name : item.type === 'bill' ? item.bill.product: item.type)}</span>
                                    </div>
                            
                                </td>
                                <td className="py-3 px-4 text-[#212121] font-Satoshi500">
                                    <div className='flex gap-1 items-center'>
                                        <img src={item.type === 'sell' ? arrow_down_green : arrow_down_red} alt="arrow" />
                                        <span>{currency === "NGN" ? '₦' : '₵'}{numberWithCommas(item.amount)}</span>
                                    </div>
                                
                                </td>
                                <td className="py-3 px-4 text-[#909090]">
                                    {moment(item.created_at).fromNow()}
                                </td>
                                <td className="py-3 px-4  text-[#212121]">
                                    <div className='flex items-center max-w-[120px] justify-center bg-[#F7F7F7] rounded-[36px] px-6 py-2'>
                                        <p className={badgeFormat(item.status)}>{capitalizeFirstLetterFormat(item.status)}</p>
                                    </div>
                                </td>     
                                </tr>
                                    ))
                                : ''}
                            </tbody>
                            )}   
                                
                            </table>
                             </div>

                             {loading && (
                                <div className="animate-pulse w-full mt-4">
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                                </div>
                            )}

                             {/* no transactions */}
                             {!loading && recentTransactions.length === 0 ? (
                                  <div className='flex flex-col min-h-[400px] justify-center items-center'>
                                  <div>
                                      <img src={notransaction} alt="no transaction icon" />
                                  </div>
                                  <div className='mt-6'>
                                      <h6 className='text-[#212121] font-Satoshi500 text-lg'>No Recent Transaction</h6>
                                  </div>
                                  <div className='mt-1'>
                                      <p className='text-[#909090] text-sm font-Satoshi400'>Trade now to get started</p>
                                  </div>
                                  <div className='mt-8'>
                                    <Link to="/trade-now" className='bg-[#235643] rounded-md
                                        disabled:bg-[#235643] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Satoshi500
                                        justify-center
                                        flex items-center min-w-[248px]
                                        py-4 px-6 text-[#FFFFFF]
                                        hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                        Trade Now
                                    </Link>
                                  </div>
  
                              </div>
                            ) : (
                                ''
                            )}
                          
                            
                        </div>

                        {/* pagination */}
                         {
                            loading ?
                                ""
                                :
                            recentTransactions.length > 0 && recentTransactions ?
                            <div className="flex justify-center mt-5 mb-10">
                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalCount={total}
                                    pageSize={PageSize}
                                    onPageChange={(page) => getMoreItemsByOffset(page)}
                                />
                            </div>
                            :
                            ""
                        }

                    </div>
                </div>
            </div>
        </>
    );
}
 
export default DashboardPage;