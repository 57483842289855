import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './Auth/auth'
import transactionReducer from './Transactions/transactions'
import tradeReducer from './Trade/trade'
import dashboardReducer from './Dashboard/dashboard'
import withdrawalReducer from './Withdrawals/withdrawal'
import notificationReducer from './Notifications/notifications'

const appReducer = combineReducers({
    auth: authReducer,
    transaction: transactionReducer,
    trade: tradeReducer,
    dashboard: dashboardReducer,
    withdrawal:withdrawalReducer,
    notification:notificationReducer,
})

const rootReducer = (state, action) => {
    if (action.type === 'auth/Logout') {
      state = undefined;
    }
    return appReducer(state, action);
  };

export default rootReducer

