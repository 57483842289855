import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast";
import {Mixpanel} from '../../components/Helpers/MixPanel'
import '../../components/Helpers/firebase'
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const getCategories = createAsyncThunk(
  'getCategories',
  async (arg, {rejectWithValue, dispatch, getState}) => {
    try {
      const res = await axios.get(
        `${apiUrl}giftcard-categories`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getGiftcardCategories = createAsyncThunk(
    'getGiftcard',
    async ({type, id}, {rejectWithValue, dispatch, getState}) => {
      try {
        const res = await axios.get(
          `${apiUrl}giftcard-categories/${type}/${id}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
)

export const getBuyGiftcards = createAsyncThunk(
  'getBuyGiftcards',
  async (arg, {rejectWithValue, dispatch, getState}) => {
    try {
      const res = await axios.get(
        `${apiUrl}giftcards/buy`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getSpecificGiftcard = createAsyncThunk(
  'getSpecificGiftcard',
  async ({id}, {rejectWithValue, dispatch}) => {
    try {
      const res = await axios.get(
        `${apiUrl}giftcards/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const uploadGiftcard = createAsyncThunk(
  'uploadGiftcard',
  async ({ file, }, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios({      
        method: "post",
        url: `${apiUrl}transactions/image-upload`,
        data: formData,
        headers: { 
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${getToken()}`
        },
        
      
    })
      if(res.status === 200){
        cogoToast.success("Image uploaded successfully!", {
          position: "bottom-right",
          });
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status === 422){
        cogoToast.error('Ooops!,an error occured while uploading image!')
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const SellGiftcard = createAsyncThunk(
  'SellGiftcard',
  async ({ card_id,amount,images, currency, comment }, { rejectWithValue, getState, dispatch }) => {
    const userId  = getState().auth.userInfo.id
    try {
      const res = await axios.post(
        `${apiUrl}transactions/sell`,
        { 
         card_id,
         amount,
         images,
         currency,
         comment
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        Mixpanel.identify(userId)
        Mixpanel.track('Sell Giftcard',{
          "Giftcard Id": card_id,
          "Amount": amount,
          "Currency": currency,
          "Comment" : comment,
          "Card Images" : images
        })
        firebase.analytics().logEvent("sell_giftcard",{user_id: userId, giftcard: card_id, amount: amount, payout_currency: currency })
        cogoToast.success("Your trade has been received and will be attended to within 5-10 minutes.", {
          position: "top-right",
        });
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status === 422){
        cogoToast.error('Oops! Looks like there was an error with this trade, please try again!')
        return rejectWithValue(error.response)
      }
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const BuyCard = createAsyncThunk(
  'BuyCard',
  async ({ card_id,card_name,card_unit,quantity,amount, currency, country_code,email }, { rejectWithValue, getState, dispatch }) => {
    const userId  = getState().auth.userInfo.id
    try {
      const res = await axios.post(
        `${apiUrl}transactions/buy`,
        { 
         card_id,
         card_name,
         card_unit,
         quantity,
         amount,
         currency,
         country_code,
         email
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        Mixpanel.identify(userId)
        Mixpanel.track('Buy Giftcard',{
          "Giftcard Id": card_id,
          "Amount": amount,
          "Currency": currency,
          "Email" : email
        })
        firebase.analytics().logEvent("buy_giftcard",{user_id: userId, giftcard: card_id, amount: amount, payout_currency: currency })
        cogoToast.success("Your giftcard purchase was successful!", {
          position: "top-right",
        });
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status === 402){
        cogoToast.error('Please check that the you have sufficient funds in your wallet!')
        return rejectWithValue(error.response)
      }
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getAvailableCountries = createAsyncThunk(
  'getAvailableCountries',
  async (arg, {rejectWithValue, dispatch, getState}) => {
    try {
      const res = await axios.get(
        `${apiUrl}giftcards/buy/countries`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getBuyGiftcardByCountry = createAsyncThunk(
  'getBuyGiftcardByCountry',
  async ({isoName}, {rejectWithValue, dispatch}) => {
    try {
      const res = await axios.get(
        `${apiUrl}giftcards/buy/${isoName}/cards`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)