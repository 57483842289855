import React from 'react'
import { CSSTransition } from 'react-transition-group'
import close_icon from '../../assets/icons/close_icon.svg'
import './modal.css'

const ModalComponent = ({showModal, show, title, children, hideClose}) => {


    return ( 
        <>
        <CSSTransition
            in={show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
         <div
          className="modal 
                    overflow-y-auto overflow-x-hidden bg-[#000000]
                    bg-opacity-50 fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full
                    opacity-0 transition-all duration-[0.3s]" >
             <div 
              onClick={(e) => e.stopPropagation()}
             className="relative p-4 w-full max-w-2xl h-full md:h-auto mx-auto my-20 modal-content translate-y-[-200px] transition-all duration-[0.3s]">
                 {/* modal content */}
                 <div className="relative bg-white rounded-[12px] py-6 px-6 md:px-10">

                       {/* modal header */}
                    <div className="flex justify-between items-center">
                        <div>
                            <h3 class="text-[15px] font-Satoshi500 text-[#000000]">
                                {title}
                            </h3>
                        </div>
                       
                      {
                        !hideClose &&
                      <div className='cursor-pointer bg-[#EDEDED] w-[25px] h-[25px] rounded-full flex items-center justify-center' onClick={showModal}>
                           <img src={close_icon} alt="close" />
                        </div>
                        }
                    </div>

                    {/* modal body */}
                    <div>
                        {children}
                    </div>

                </div>

             </div>
        </div>  
        </CSSTransition> 
        </>
     );
}
 
export default ModalComponent;