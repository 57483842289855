import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
// import cogoToast from "cogo-toast"


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getRecentTransactions = createAsyncThunk(
  'getRecentTransactions',
  async ({page}, {rejectWithValue, dispatch, getState}) => {
    const currency  = getState().auth.currency

    try {
      const res = await axios.get(
        `${apiUrl}transactions?page=${page}&currency=${currency}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getCardTransactions = createAsyncThunk(
  'getCardTransactions',
  async ({page}, {rejectWithValue, dispatch, getState}) => {
    const currency  = getState().auth.currency
    try {
      const res = await axios.get(
        `${apiUrl}transactions?page=${page}&currency=${currency}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getWalletTransactions = createAsyncThunk(
  'getWalletTransactions',
  async ({page}, {rejectWithValue, dispatch, getState}) => {
    const currency  = getState().auth.currency
    try {
      const res = await axios.get(
        `${apiUrl}transactions/wallets?page=${page}&currency=${currency}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getBillTransactions = createAsyncThunk(
  'getBillTransactions',
  async ({page}, {rejectWithValue, dispatch, getState}) => {
    const currency  = getState().auth.currency
    try {
      const res = await axios.get(
        `${apiUrl}transactions/bills?page=${page}&currency=${currency}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getATransaction = createAsyncThunk(
  'getATransaction',
  async ({reference}, {rejectWithValue, dispatch}) => {
    try {
      const res = await axios.get(
        `${apiUrl}transactions/${reference}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

