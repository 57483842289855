import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { SellCardValidator } from '../../validationSchema/validator'
import { getBuyGiftcards, getCategories, getGiftcardCategories, getSpecificGiftcard } from '../../redux/Trade/tradeActions'
import { useSelector, useDispatch } from 'react-redux'
import chevron_grey from '../../assets/icons/chevron_grey.svg'
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom'


const RateCalculatorPage = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [tagId] =  useState(1)
   
    const {categories, giftcards, terms} = useSelector((state) => state.trade);


    const [cardSelected, setCardSelected] = useState("")
    const [rateInfo, setRateInfo] = useState({})
    const [payoutAmount, setPayoutAmount] = useState(0)

    const handleCategory = (val) =>{
        if(tagId === 1){
            dispatch(getGiftcardCategories({
                type: 'sell',
                id: val
            }))
        }
        else{
            dispatch(getGiftcardCategories({
                type: 'buy',
                id: val
            }))
        } 
    }

    const handleGiftcard = (id) =>{
        let card = giftcards.find((val => val.id === parseInt(id)))
        let cardName = giftcards.find((val => val.id === parseInt(id))).name
        setCardSelected(cardName)
        setRateInfo({
            ...rateInfo,
            rate: card.rate,
            GHSRate: card.rates.GHS,
            NGNRate: card.rates.NGN
        })
        // get a specific card
        dispatch(getSpecificGiftcard({
            id: id
        }))
    }

    const handleCalculation = (val, wallet, card) =>{
        if(val === "" || card === ""){
            setPayoutAmount(0)
        }
        else{
            if(wallet === "GHS"){
                    let amt = parseFloat(val) * rateInfo.rate / rateInfo.GHSRate
                    setPayoutAmount(amt.toFixed(2))  
            }
            else{
                let amt = parseFloat(val) * rateInfo.rate / rateInfo.NGNRate
                setPayoutAmount(amt.toFixed(2))
            } 
        }
       
    }

    const handlePayoutSelected = (currency,card, amount) =>{
        if(card && amount !== ""){
            if(currency === "GHS"){
                let amt = parseFloat(amount) * rateInfo.rate / rateInfo.GHSRate
                setPayoutAmount(amt.toFixed(2))  
            }
            else{
                let amt = parseFloat(amount) * rateInfo.rate / rateInfo.NGNRate
                setPayoutAmount(amt.toFixed(2))
            }
        }
    }


    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    const ProceedTrade = (values)=>{
       console.log(values)
       navigate('/trade-now')
    }

    useEffect(()=>{
        dispatch(getCategories())
    },[dispatch])


    return ( 
        <>
             <div>
                  <h4 className='text-[#212121] font-Satoshi700 text-sm md:text-lg'>Rate Calculator</h4>

                  <div className='grid md:grid-cols-3 gap-6'>
                        <div className='mt-8 md:col-span-2'>
                          

                            <div className='mt-2'>
                                <p className='text-[#909090] font-Satoshi500 text-sm'>Get the current value for your transaction</p>
                            </div>

                           <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                ProceedTrade(values, setSubmitting)
                                }
                                validationSchema={SellCardValidator}
                                initialValues={{
                                    category: '',
                                    giftcard: '',
                                    amount: '',
                                    wallet: 'NGN',
                                    comment: ''
                            }}
                            >
                                {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                setFieldValue,
                                setFieldTouched,
                                values,
                                touched,
                                errors,
                                isValid,
                                dirty
                                }) => (
                                <Form className="mt-10" onSubmit={handleSubmit}>
                                    <div className='grid md:grid-cols-2 gap-4 mt-10'>
                                    <div>
                                            <label htmlFor='category' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                            Gift Card<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                            </label>
                                            <div className='relative'>
                                                <select
                                                    type="text"
                                                    name="category"
                                                    value={values.category}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handleCategory(e.currentTarget.value);
                                                        setFieldTouched('category', false)
                                                        setFieldValue('giftcard', "")
                                                        setFieldValue('amount', "")
                                                        setCardSelected("")
                                                        setPayoutAmount(0)
                                                    }}
                                                    placeholder="Select Giftcard"
                                                    className={
                                                        touched.category && errors.category
                                                            ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                            : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                        }  
                                                >
                                                    <option value="" selected disabled>Select Category</option>
                                                    {categories.map((opt, index) => {
                                                            return <option key={index} value={opt.id}>{opt.name}</option>
                                                    })}   
                                                </select>
                                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                            </div>
                                            {touched.category && errors.category ? (
                                                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                        {touched.category && errors.category}
                                                    </small>
                                            ) : null}
                                        </div>
                                        <div>
                                            <label htmlFor='giftcard' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                            Category<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                            </label>
                                            <div className='relative'>
                                                <select
                                                    type="text"
                                                    name="giftcard"
                                                    value={values.giftcard}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        setFieldValue('amount', "")
                                                        handleGiftcard(e.currentTarget.value);
                                                        setPayoutAmount(0)
                                                        setFieldTouched('giftcard', false)
                                                    }}
                                                    placeholder="Select Category"
                                                    className={
                                                        touched.giftcard && errors.giftcard
                                                            ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                            : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                        }   
                                                >
                                                    <option value="" selected disabled>Select Category</option>
                                                    {giftcards.map((opt, index) => {
                                                            return <option key={index} value={opt.id}>{opt.name}</option>
                                                    })}   
                                                </select>
                                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                            </div>
                                            {touched.giftcard && errors.giftcard ? (
                                                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                        {touched.giftcard && errors.giftcard}
                                                    </small>
                                            ) : null}
                                        </div>
                                        <div className='mt-4'>
                                            <label htmlFor='amount' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                            Amount<sup className='text-[#DC0000] text-xs top-[-0.2em] left-[-0.01em]'>*</sup>
                                            </label>
                                            <input
                                                type="number"
                                                name="amount"
                                                maxLength={10}
                                                placeholder="100"
                                                value={values.amount}
                                                className={
                                                    touched.amount && errors.amount
                                                        ? 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                        : 'appearance-none w-full placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                } 
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    setFieldTouched('amount', false)
                                                    handleCalculation(e.currentTarget.value, values.wallet, values.giftcard);
                                                }}
                                            />
                                            {touched.amount && errors.amount ? (
                                                <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                            {touched.amount && errors.amount}
                                                        </small>
                                            ) : null}
                                        </div>
                                        <div className='mt-4'>
                                            <label htmlFor='wallet' className="block mb-2 font-Satoshi500 text-[#909090] text-xs">
                                            Payout Wallet
                                            </label>
                                            <div className='relative'>
                                                <select
                                                    type="text"
                                                    name="wallet"
                                                    placeholder="Select Wallet"
                                                    className={
                                                        touched.wallet && errors.wallet
                                                            ? 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px]  text-[#212121] text-sm focus:border-[#DB2424] focus:outline-none rounded-md border border-[#DB2424] bg-[#F7F9FB] py-4 px-4'
                                                            : 'appearance-none w-full cursor-pointer placeholder:text-[#B9B9B9] placeholder:text-[13px] text-[#212121] text-sm  border-none focus:outline-none rounded-md  bg-[#F7F9FB] py-4 px-4'
                                                    } 
                                                    value={values.wallet}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        handlePayoutSelected(e.currentTarget.value, values.giftcard, values.amount);
                                                    }}  
                                                >
                                                    <option value="NGN" selected>Naira (NGN)</option>
                                                    <option value="GHS">Cedis (GHS)</option>
                                                    
                                                </select>
                                                <img className='absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer' src={chevron_grey} alt="arrow" />
                                            </div>
                                            {touched.wallet && errors.wallet ? (
                                                    <small className="text-[#DB2424] font-[300] text-[10px] pt-[4px]">
                                                        {touched.wallet && errors.wallet}
                                                    </small>
                                            ) : null}
                                        </div>
                                        <div className='mt-4'>
                                            <p className='mb-2 text-[#909090] font-Satoshi500 text-xs'>Calculated Amount</p>
                                            <div className='bg-[#EEF0F2] py-4 px-6 mt-3'>
                                            <p className='text-[#212121] font-Satoshi500 text-sm'>{cardSelected ? cardSelected : 'Card selected shows here'}</p>
                                            </div>
                                                <div className='bg-[#F7F9FB] rounded-[4px] py-4 px-6 flex justify-between'>
                                                <p className='text-[#212121] font-Satoshi500 text-sm'>Total</p>
                                                <p className='text-[#212121] font-Satoshi500 text-sm'>{values.wallet} {payoutAmount === 0  ? "0.00" : numberWithCommas(payoutAmount)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='grid md:grid-cols-2 gap-4'>
                                        <div className='mt-10 '>
                                                <button 
                                                disabled={false}
                                                className='bg-[#235643] rounded-[4px]
                                                    disabled:bg-[#235643] 
                                                    disabled:opacity-[0.7]
                                                    text-sm
                                                    font-Satoshi400
                                                    justify-center w-full
                                                    flex items-center 
                                                    py-4 px-6 text-[#FFFFFF]
                                                    hover:bg-[#235643] hover:opacity-[0.9] transition-all'> 
                                                    Proceed to Trade
                                                </button>
                                        </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                           
                        </div>
                         <div className=''>
                             <div className='bg-[#F9F9F9] rounded-[4px] mt-6 max-w-full max-h-[500px] overflow-y-scroll'>
                                <div className='bg-[#EEF0F2] py-4 px-4'>
                                    <h6 className='text-[#000000] font-Satoshi700 text-sm'>Terms of Trade</h6>
                                </div>
                                <div className='pt-4 pb-10 px-4 text-[#212121] text-[13px] font-Satoshi400'>
                                   {
                                    terms
                                    ?
                                    parse(terms) 
                                    :
                                    <p className='text-center  mt-5'>
                                     Terms would appear here once a giftcard is selected!
                                    </p>
                                   }  
                                </div>
                            </div>   
                        </div>    
                  </div>
         
            </div>
        </>
     );
}
 
export default RateCalculatorPage;